import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { getAllUsers, getProducerUsers, getReturoUsers, getTraderUsers } from '../api/users'
import { listState, listReducers } from './utils'

const loadUsers = createAsyncThunk('users/loadUsers', async (_, { getState, rejectWithValue }) => {
    try {
        const { page, pageSize, search, order, filter } = getState().users
        const { currentPartnerId, isManager, isProducer, isRetailer } = getState().auth

        const params = {
            PageNumber: page,
            PageSize: pageSize,
        }

        for (let [key, values] of Object.entries(filter)) {
            if ((values || []).length) params[key] = values.join(',')
        }

        if (search.length > 2) params['SearchText'] = search
        if (order) params['order'] = order

        if (isManager) {
            const response = await getReturoUsers(params)
            return response
        }
        if (isProducer) {
            const response = await getProducerUsers(currentPartnerId, params)
            return response
        }
        if (isRetailer) {
            const response = await getTraderUsers(currentPartnerId, params)
            return response
        }

        const response = await getAllUsers(params)
        return response
    } catch (error) {
        return rejectWithValue([], error)
    }
})

const slice = createSlice({
    name: 'users',
    initialState: listState,
    reducers: {
        ...listReducers,
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadUsers.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.error = null
                const { Items = [], TotalItemCount = 0 } = payload
                state.items = Items
                state.total = TotalItemCount
            })
            .addCase(loadUsers.pending, (state) => {
                state.isLoading = true
            })
            .addCase(loadUsers.rejected, (state, { error }) => {
                state.isLoading = false
                state.error = error
            })
    },
})

const users = slice.reducer
const actions = {
    loadUsers,
    ...slice.actions,
}
export { users }
export default actions
