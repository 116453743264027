import { signedAPI, ENDPOINTS, multipart, payload2formdata } from './api'

export const getCollectionPoints = async (params) => {
    const { data } = await signedAPI.get(ENDPOINTS.admin.collection_points, { params })
    return data
}

export const getCPS = async (trader_id, params = {}) => {
    const { data } = await signedAPI.get(ENDPOINTS.traders.collection_points(trader_id), { params })
    return data
}

export const getCPSinCSV = async (partner_id) => {
    const { data } = await signedAPI.get(ENDPOINTS.traders.collection_points_csv(partner_id))
    return data
}

export const getCP = async (cp_id) => {
    const { data } = await signedAPI.get(ENDPOINTS.traders.collection_points_id(cp_id))
    return data
}

export const exportCP = async (cp_id) => {
    const { data } = await signedAPI.get(ENDPOINTS.traders.collection_points_export(cp_id))
    return data
}

export const createCP = async (payload) => {
    const { TraderId } = payload
    const { data } = await signedAPI.post(ENDPOINTS.traders.collection_points(TraderId), payload)
    return data
}

export const updateCP = async (cp_id, payload) => {
    const { TraderId, TraderName, ...rest } = payload
    const { data } = await signedAPI.put(ENDPOINTS.traders.collection_points_id(cp_id), rest)
    return data
}

export const deactivateCP = async (cp_id) => {
    const { data } = await signedAPI.delete(ENDPOINTS.traders.collection_points_id(cp_id))
    return data
}

export const uploadCPs = async (TraderId, file) => {
    const { data } = await signedAPI.post(
        ENDPOINTS.traders.collection_points_upload(TraderId),
        payload2formdata({ file }),
        {
            headers: multipart,
        }
    )
    return data
}

export const registerCP = async (trader_id, cp_id) => {
    const { data } = await signedAPI.put(ENDPOINTS.traders.collection_points_register(trader_id, cp_id))
    return data
}

export const getRVMMachines = async (trader_id, cp_id) => {
    const { data } = await signedAPI.get(ENDPOINTS.traders.collection_points_rvm(trader_id, cp_id))
    return data
}

export const addRVMMachine = async (trader_id, cp_id, payload) => {
    const { data } = await signedAPI.post(ENDPOINTS.traders.collection_points_rvm(trader_id, cp_id), payload)
    return data
}

export const updateRVMMachine = async (trader_id, cp_id, rvmMachineId, payload) => {
    const { data } = await signedAPI.put(
        ENDPOINTS.traders.collection_points_rvm_id(trader_id, cp_id, rvmMachineId),
        payload
    )
    return data
}

export const geocodeByAddress = async (address, params) => {
    const { data } = await signedAPI.get(ENDPOINTS.locations.geocodeByAddress, { params: { address, ...params } })
    return data
}

export const geocodeByCoordinate = async (latlng, params) => {
    const { data } = await signedAPI.get(ENDPOINTS.locations.geocodeByLatLng, { params: { latlng, ...params } })
    return data
}
