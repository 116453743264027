const Avatar = ({ user, size = 40, className = '', ...rest }) => {
    const initials =
        (user?.name || '')
            .split(' ')
            .map((v) => v[0])
            .filter((v) => !!v)
            .slice(0, 2)
            .join('')
            .toUpperCase() || '?'

    const containerStyle = {
        width: size,
        height: size,
        minWidth: size,
        overflow: 'hidden',
        ...rest,
    }

    const style = {
        backgroundImage: `url(${user?.image_url})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: size,
        height: size,
        position: 'absolute',
    }

    return (
        <div style={containerStyle} className={'rounded-circle relative border ' + className}>
            <div className={'regular-initials' + (size < 40 ? ' small-initials' : '')}>{initials}</div>
            <div style={style} className='centered'></div>
        </div>
    )
}

export default Avatar
