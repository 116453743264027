import { useRoutes } from 'react-router-dom'

import AuthHeader from './AuthHeader'
import FooterCommon from './FooterCommon'

import ResetContent from './ResetContent'
import { authRoutes } from './routes'

const AuthApp = () => {
    const routes = useRoutes(authRoutes)

    return (
        <div className='bg-auth'>
            <AuthHeader />
            <div className='AuthApp'>{routes}</div>
            <FooterCommon containerClass='bg-auth-50' callCenterClass='text-primary' isAuth />
            <ResetContent userinfo={true} />
        </div>
    )
}

export default AuthApp
