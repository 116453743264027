import axios from 'axios'
import ENDPOINTS from './endpoints'

import store from '../reducers'
import auth from '../reducers/auth'

import { query } from './utils'
export * from './utils'

const log = (...values) => {
    if (ENDPOINTS.env === 'prod') return
    //if (process.env.NODE_ENV !== 'production')
    console.log(...values)
}

const logRequest = (request) => {
    log(
        request.method.toUpperCase(),
        request.baseURL + request.url + query(request.params),
        request.data,
        request.headers
    )
}

const logResponse = (response) => {
    log(response.status, response.config.baseURL + response.config.url, response.data)
}

const logError = (error) => {
    logResponse({ ...error, ...error.response })
}

const openAPI = axios.create({
    baseURL: ENDPOINTS.baseURL,
})

const signedAPI = axios.create({
    baseURL: ENDPOINTS.baseURL,
})

const extractMessages = (error) => {
    const { data = {} } = error.response || {}
    const { title, errors = {}, detail = '' } = data
    const result = Object.values(errors).flat()
    const combined = [title || error.title, detail, ...result].filter(Boolean)
    return combined.length > 0 ? combined.join('\n') : 'io_error'
}

openAPI.interceptors.request.use((request) => {
    logRequest(request)
    return request
})

openAPI.interceptors.response.use(
    (response) => {
        logResponse(response)
        return response
    },
    async (error) => {
        const result = {
            ...error,
            message: extractMessages(error),
        }
        return Promise.reject(result)
    }
)

signedAPI.interceptors.request.use((request) => {
    const idToken = global.msal?.idToken
    request.headers['Authorization'] = idToken ? `Bearer ${idToken}` : null
    logRequest(request)
    return request
})

signedAPI.interceptors.response.use(
    (response) => {
        logResponse(response)
        return response
    },
    async (error) => {
        //debugger
        //        console.log(error)
        // if (!error.response) {
        //     window.location.reload()
        // }

        if (error.response?.status === 401) {
            console.log('401')
            store.dispatch(auth.setMSALResponse(null))
        }

        logError(error)
        const result = {
            ...error,
            message: extractMessages(error),
        }
        return Promise.reject(result)
    }
)

export { openAPI, signedAPI, ENDPOINTS }
