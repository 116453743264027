import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'

import { def } from './default'
import { auth } from './auth'
import { apps } from './apps'
import { users } from './users'
import { cps } from './cps'
import { locations } from './locations'
import { reptrader } from './reptrader'
import { reproducer } from './reproducer'
import { docs } from './docs'

const logMiddleware = createLogger({
    predicate: () => process.env.NODE_ENV !== 'production',
    collapsed: true,
})

const reducers = combineReducers({
    def,
    auth,
    apps,
    users,
    cps,
    locations,
    reptrader,
    reproducer,
    docs,
})

const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(logMiddleware),
    devTools: process.env.NODE_ENV !== 'production',
})

export default store
