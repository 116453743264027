import { useSelector } from 'react-redux'

const useAuth = () => {
    const auth = useSelector((state) => state.auth)

    const isAdmin = auth.isManager
        ? auth.permissions.some((v) => v.role === 'Admin')
        : auth.permissions.some((v) => v.role === 'Admin' && v.entity_id === auth.currentPartnerId)

    return { ...auth, isAdmin }
}

export default useAuth
