import { format, isValid, parseISO, add } from 'date-fns'
import { ro } from 'date-fns/locale'

export { add }

export const mmMMyyyy = (date) => {
    return format(date, 'dd.MM.yyyy')
}

export const restoreLanguage = () => {
    return localStorage.getItem('@returo_language')
}

export const saveLanguage = (value) => {
    localStorage.setItem('@returo_language', value)
}

export const detectLocale = () => {
    try {
        const { navigator } = window
        const all = [...navigator.languages].map((v) => v.split('-')[0] || v)
        const languages = Array.from(new Set(all))
        return languages[0]
    } catch (e) {
        return null
    }
}

export const restoreAuthInfo = () => {
    const value = localStorage.getItem('@returo_auth')
    try {
        return JSON.parse(value)
    } catch (e) {
        return null
    }
}

export const saveAuthInfo = (value) => {
    localStorage.setItem('@returo_auth', JSON.stringify(value))
}

export const splitName = (name) => {
    const [firstName, ...rest] = name.split(' ')
    return {
        firstName,
        lastName: rest.join(' '),
    }
}

export const timeFormatted = (timeString) => {
    const [h = '', m = ''] = timeString.split(':')
    return time00(h, m)
}

export const time00 = (h, m) => {
    const hh = `00${h}`.slice(-2)
    const mm = `00${m}`.slice(-2)
    const value = `${hh}:${mm}`
    return value
}

export const getFromTo = (left, right) => {
    const from = left ? ('0' + left).slice(-5) : ''
    const to = right ? ('0' + right).slice(-5) : ''
    return [from, to]
}

export const getTotalTime = (intervals) => {
    let totalH = 0
    let totalM = 0

    const items = intervals || []

    for (let index = 0; index < items.length; index++) {
        const v = items[index]

        const [left, right] = v.split('-')
        const [from, to] = getFromTo(left, right)

        for (let inner = 0; inner < items.length; inner++) {
            const v2 = items[inner]

            const [left2, right2] = v2.split('-')
            const [from2, to2] = getFromTo(left2, right2)

            if (index === inner) continue

            if (from2 === from || to2 === to || (from2 > from && from2 < to) || (to2 > from && to2 < to)) {
                return [0, 0]
            }
        }
    }

    items.forEach((v) => {
        const [left, right] = v.split('-')
        const [from, to] = getFromTo(left, right)

        if (from && to) {
            const [fh, fm] = from.split(':')
            const [th, tm] = to.split(':')
            const hours = +th - +fh
            const minutes = +tm - +fm

            if (!isNaN(hours) && !isNaN(minutes)) {
                totalH += hours
                totalM += minutes
            }
        }
    })

    if (totalM < 0) {
        while (totalM < 0) {
            totalH -= 1
            totalM += 60
        }
    } else {
        while (totalM >= 60) {
            totalH += 1
            totalM -= 60
        }
    }

    return [totalH, totalM]
}

export const getTotalTimeFormatted = (intervals) => {
    const [totalH, totalM] = getTotalTime(intervals)
    const result = totalH + totalM ? time00(totalH, totalM) + 'h' : ''
    return result
}

export const formattedDate = (date) => {
    return isValid(date) ? format(date, 'dd.MM.yyyy') : null
}

export const formattedDateTime = (date) => {
    return isValid(date) ? format(date, 'dd.MM.yyyy HH:mm') : null
}

export const formattedDateTimeSeconds = (date) => {
    return format(date, 'dd.MM.yyyy HH:mm:ss')
}

export const parseDate = (string) => {
    try {
        if (string === '0001-01-01T00:00:00+00:00') return null
        const date = parseISO(string)
        return isValid(date) ? date : null
    } catch {
        return null
    }
}

export const dateOrNull = (string) => {
    try {
        const date = parseISO(string)
        return date ? formattedDate(date) : null
    } catch {
        return null
    }
}

export const datetimeOrNull = (string) => {
    try {
        const date = parseISO(string)
        return date ? formattedDateTimeSeconds(date) : null
    } catch {
        return null
    }
}

export const cleanup = (input) => {
    const obj = { ...input }
    Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key])
    return obj
}

export const cleanupNulls = (input) => {
    const obj = { ...input }
    Object.keys(obj).forEach(
        (key) => (obj[key] === undefined || obj[key] === '' || obj[key] === null) && delete obj[key]
    )
    return obj
}

export const cleanupNullsKeepEmptyStrings = (input) => {
    const obj = { ...input }
    Object.keys(obj).forEach((key) => (obj[key] === undefined || obj[key] === null) && delete obj[key])
    return obj
}

export const monthString = (year, month) => {
    const date = new Date(year, month - 1, 1)
    return format(date, 'MMMM', {
        locale: restoreLanguage() === 'ro' ? ro : null,
    })
}

export const periodString = (year, month) => {
    const date = new Date(year, month - 1, 1)
    return format(date, 'MMMM yyyy', {
        locale: restoreLanguage() === 'ro' ? ro : null,
    })
}

export const fullDateString = (date) => {
    return format(date, 'dd MMMM yyyy', {
        locale: restoreLanguage() === 'ro' ? ro : null,
    })
}

export const delay = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time))
}

export const download = (filename, value, type = 'text/plain') => {
    const file = new Blob([value], { type: type })
    downloadFile(filename, file)
}

export const downloadFile = (filename, file) => {
    const element = document.createElement('a')
    element.href = URL.createObjectURL(file)
    element.download = filename
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
}

export const downloadLink = (filename, href) => {
    const element = document.createElement('a')
    element.href = href
    element.download = filename
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
}
