export const listState = {
    isLoading: false,
    items: [],
    total: 0,

    page: 0,
    pageSize: 10,

    text: '',
    order: '',
    search: '',
    filter: {},
}

export const listReducers = {
    reset: () => {
        return listState
    },
    setPage: (state, action) => {
        state.page = action.payload
    },
    setPageSize: (state, action) => {
        state.pageSize = action.payload
        if (action.payload > state.pageSize) state.page = 0
    },

    setSearch: (state, action) => {
        state.text = action.payload
        if (action.payload.length > 2) {
            state.search = action.payload
            state.page = 0
        } else {
            state.search = ''
        }
    },
    setFilter: (state, action) => {
        state.filter = action.payload
        state.page = 0
    },
    resetFilter: (state, action) => {
        const copy = { ...state.filter }
        delete copy[action.payload]
        state.filter = copy
    },
    setOrder: (state, action) => {
        state.order = action.payload
    },
}
