import { useTranslation } from 'react-i18next'

export const validationStates = {
    true: 'was-validated is-valid',
    false: 'was-validated is-invalid',
}

const TextInput = ({
    title = null,
    placeholder = null,
    className = '',
    leading,
    trailing,
    notEditable = [],
    validation,
    errorMessage,
    containerClass = 'mb-3',
    size = 'md',
    ...props
}) => {
    const { t } = useTranslation()

    const val = typeof validation === 'boolean' ? validation : [null, undefined].includes(validation) ? null : false
    const validationClass = validationStates[val] || ''

    const e_message = validationClass
        ? typeof validation === 'string'
            ? t(validation)
            : Array.isArray(validation)
            ? validation.map((v) => t(v)).join(' ')
            : t(errorMessage)
        : null

    // console.log(val, validation, e_message)

    return (
        <div className={containerClass}>
            {title && (
                <label className='form-label text-nowrap'>
                    {t(title)}
                    {props.required && <span className='text-danger ms-1'>*</span>}
                </label>
            )}
            <div className='input-group'>
                {leading}
                <input
                    data-testid={props.name}
                    className={`form-control ${size} ${validationClass} ${className}`}
                    placeholder={placeholder ? t(placeholder) : null}
                    disabled={props.disabled || notEditable.includes(props.name)}
                    type='text'
                    maxLength={255}
                    {...props}
                />
                {trailing}
            </div>
            {e_message && validationClass.includes('is-invalid') && (
                <div className='small text-danger'>{e_message}</div>
            )}
        </div>
    )
}

export default TextInput
