import { openAPI, ENDPOINTS, multipart, blob, payload2formdata, signedAPI } from './api'

export const registerTrader = async (payload) => {
    const { data } = await openAPI.post(ENDPOINTS.traders.registration, payload2formdata(payload), {
        headers: multipart,
    })
    return data
}

export const registerProducer = async (payload) => {
    const { data } = await openAPI.post(ENDPOINTS.producers.registration, payload2formdata(payload), {
        headers: multipart,
    })
    return data
}

export const getTrader = async (id) => {
    const { data } = await openAPI.get(ENDPOINTS.traders.registration_id(id))
    return data
}

export const getProducer = async (id) => {
    const { data } = await openAPI.get(ENDPOINTS.producers.registration_id(id))
    return data
}

export const getTraderCertificate = async (id) => {
    const { data } = await signedAPI.get(ENDPOINTS.traders.registration_certificate(id), blob)
    return data
}

export const getProducerCertificate = async (id) => {
    const { data } = await signedAPI.get(ENDPOINTS.producers.registration_certificate(id), blob)
    return data
}

export const getIdentity = async () => {
    const { data } = await signedAPI.get(ENDPOINTS.identity.identity)
    return data
}

export const getAuthorizationCode = async (partner_id, user_id) => {
    const { data } = await signedAPI.post(ENDPOINTS.identity.challenge(partner_id, user_id))
    return data
}

export const verifyIdentity = async (partner_id, user_id, AuthorizationCode) => {
    const { data } = await signedAPI.get(ENDPOINTS.identity.verify(partner_id, user_id), {
        params: { AuthorizationCode },
    })
    return data
}

export const saveTrader = async (id, payload) => {
    const { data } = await signedAPI.put(ENDPOINTS.traders.registration_id(id), payload)
    return data
}

export const saveProducer = async (id, payload) => {
    const { data } = await signedAPI.put(ENDPOINTS.producers.registration_id(id), payload)
    return data
}

export const deactivateProducer = async (partner_id) => {
    const { data } = await signedAPI.put(ENDPOINTS.producers.registration_id_deactivate(partner_id))
    return data
}
export const deactivateTrader = async (partner_id) => {
    const { data } = await signedAPI.put(ENDPOINTS.traders.registration_id_deactivate(partner_id))
    return data
}

export const getTraderContract = async (trader_id, version) => {
    const { data } = await signedAPI.get(ENDPOINTS.traders.contract(trader_id), {
        params: { version },
        ...blob,
    })
    return data
}

export const getProducerContract = async (producer_id, version) => {
    const { data } = await signedAPI.get(ENDPOINTS.producers.contract(producer_id), {
        params: { version },
        ...blob,
    })
    return data
}

export const sendTradeSignedContract = async (trader_id, file) => {
    const { data } = await signedAPI.post(ENDPOINTS.traders.contract_signed(trader_id), payload2formdata({ file }), {
        headers: multipart,
    })
    return data
}

export const sendProducerSignedContract = async (producer_id, file) => {
    const { data } = await signedAPI.post(
        ENDPOINTS.producers.contract_signed(producer_id),
        payload2formdata({ file }),
        {
            headers: multipart,
        }
    )
    return data
}

export const sendTraderContractSignature = async (trader_id, payload) => {
    const { data } = await signedAPI.post(ENDPOINTS.traders.contract_signature(trader_id), payload2formdata(payload), {
        headers: multipart,
    })
    return data
}

export const sendProducerContractSignature = async (producer_id, payload) => {
    const { data } = await signedAPI.post(
        ENDPOINTS.producers.contract_signature(producer_id),
        payload2formdata(payload),
        {
            headers: multipart,
        }
    )
    return data
}

export const finalizeTraderContract = async (trader_id) => {
    const { data } = await signedAPI.put(ENDPOINTS.traders.contract_finalize(trader_id))
    return data
}

export const finalizeProducerContract = async (producer_id) => {
    const { data } = await signedAPI.put(ENDPOINTS.producers.contract_finalize(producer_id))
    return data
}

export const setRetailerDeclaration = async (trader_id, Type) => {
    const { data } = await signedAPI.put(ENDPOINTS.traders.retailer_declaration(trader_id), { Type })
    return data
}
