import { useEffect, useState } from 'react'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

import ENDPOINTS from '../api/endpoints'

const webAPI = axios.create({ baseURL: ENDPOINTS.webURL })

const getCommit = async () => {
    try {
        const { data } = await webAPI.get('/downloads/commit.txt', {
            params: {
                timestamp: new Date().getTime(),
            },
        })
        return data
    } catch (e) {
        return {}
    }
}

const update = {
    position: 'fixed',
    left: 20,
    bottom: 20,
    padding: 10,
    backgroundColor: '#FF4444E0',
    color: '#FFF',
    zIndex: 2000,
    borderRadius: 8,
    textAlign: 'center',
    boxShadow: '5px 5px 5px gray',
}

const UpdateBox = () => {
    const [commit, setCommit] = useState(null)
    const { t } = useTranslation()

    useEffect(() => {
        const timeout_time = (process.env.REACT_APP_ENV === 'dev' ? 1 : 5) * 60 * 1000
        let timer = null

        const load = async () => {
            const data = await getCommit()
            // console.log(data)
            setCommit(data.commit)
            timer = setTimeout(() => {
                load()
            }, timeout_time)
        }
        if (process.env.NODE_ENV === 'production') load()

        return () => {
            clearTimeout(timer)
        }
    }, [])

    // console.log(ENDPOINTS.commit, commit, process.env.NODE_ENV)
    const hasUpdate = commit && ENDPOINTS.commit && commit !== ENDPOINTS.commit

    if (!hasUpdate) return null

    const onClick = () => {
        window.location.reload()
    }

    return (
        <div style={update} onClick={onClick}>
            <div>{t('update_available')}</div>
            <div>{t('click_to_refresh')}</div>
        </div>
    )
}

export default UpdateBox
