import { initializeApp } from 'firebase/app'
import { getAnalytics, setUserProperties, logEvent } from 'firebase/analytics'

import firebaseConfig from './firebase-config'

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

const setAnalyticsUser = (user) => {
    setUserProperties(analytics, {
        user_id: `${user?.id || 'n/a'}`,
        full_name: user?.name || 'Not authorized',
    })
}

const logAnalyticsEvent = (event = 'select_content', payload = {}) => {
    logEvent(analytics, event, payload)
}

const mapApiKey = firebaseConfig.apiKey

export { app, analytics, setAnalyticsUser, logAnalyticsEvent, mapApiKey }
export default app
