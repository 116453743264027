const firebaseConfigPROD = {
    apiKey: 'AIzaSyBu7bgtoWAFSCNgr9PhXR8jsaAjwrFfQLo',
    authDomain: 'returo-sensoneo.firebaseapp.com',
    projectId: 'returo-sensoneo',
    storageBucket: 'returo-sensoneo.appspot.com',
    messagingSenderId: '139664979675',
    appId: '1:139664979675:web:d857d0ec841315c4c6befe',
    measurementId: 'G-BFC55N0YLL',
}

const firebaseConfigDEV = {
    apiKey: 'AIzaSyBu7bgtoWAFSCNgr9PhXR8jsaAjwrFfQLo',
    authDomain: 'returo-sensoneo.firebaseapp.com',
    projectId: 'returo-sensoneo',
    storageBucket: 'returo-sensoneo.appspot.com',
    messagingSenderId: '139664979675',
    appId: '1:139664979675:web:94370f93dd719a17c6befe',
    measurementId: 'G-EX4L922J5L',
}

const firebaseConfigQA = {
    apiKey: 'AIzaSyBu7bgtoWAFSCNgr9PhXR8jsaAjwrFfQLo',
    authDomain: 'returo-sensoneo.firebaseapp.com',
    projectId: 'returo-sensoneo',
    storageBucket: 'returo-sensoneo.appspot.com',
    messagingSenderId: '139664979675',
    appId: '1:139664979675:web:22029866cb6f2a9ac6befe',
    measurementId: 'G-RKCP78H0ZY',
}

const configs = {
    dev: firebaseConfigDEV,
    qa: firebaseConfigQA,
    uat: firebaseConfigQA,
    prod: firebaseConfigPROD,
}

const env = process.env.REACT_APP_ENV || 'dev'

const config = configs[env] || firebaseConfigPROD

export default config
