// import { signedAPI, ENDPOINTS } from './api'

import { getTraderReports, getProducerReports } from './admin'

export const getDocs = async (isProducer, PartnerId, params) => {
    const data = isProducer
        ? await getProducerReports({ ...params, ProducerId: PartnerId })
        : await getTraderReports({ ...params, TraderId: PartnerId })
    return data
}
