const multipart = {
    'Content-Type': 'multipart/form-data',
}

const blob = {
    responseType: 'blob',
}

const payload2formdata = (payload) => {
    const formdata = new FormData()
    Object.entries(payload).forEach(([k, v]) => {
        formdata.append(k, v)
    })
    return formdata
}

const query = (obj) => {
    try {
        if (!obj) return ''
        let str = []
        for (let [key, value] of Object.entries(obj)) {
            if (Array.isArray(value)) str.push(`${key}=${value.joined(',')}`)
            else str.push(`${key}=${value}`)
        }
        return str.length ? '?' + str.join('&') : ''
    } catch {
        return JSON.stringify(obj)
    }
}

export { multipart, blob, payload2formdata, query }
