import { signedAPI, ENDPOINTS } from './api'

export const getPartners = async (params) => {
    const { data } = await signedAPI.get(ENDPOINTS.admin.partners, { params })
    return data
}

export const getPartner = async (id) => {
    const { data } = await signedAPI.get(ENDPOINTS.admin.partners, { params: { id } })
    const result = data.Items[0]
    return result
}

export const getTraderReports = async (params) => {
    const { data } = await signedAPI.get(ENDPOINTS.admin.trader_reports, { params })
    return data
}

export const getProducerReports = async (params) => {
    const { data } = await signedAPI.get(ENDPOINTS.admin.producer_reports, { params })
    return data
}
