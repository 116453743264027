import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Drawer from 'react-modern-drawer'

import HeaderMenu from './components/HeaderMenu'
import HeaderDropDown from './components/HeaderDropDown'
import UserInfo from './components/UserInfo'

import { Bars, Logo } from '../resources/icons'
import { logoProps, getNavHeight } from './utils'
import SideMenu from './SideMenu'
import LangSelector from './components/LangSelector'

import useScroll from '../hooks/useScroll'
import useWH from '../hooks/useWH'
import useHeaderItems from './useHeaderItems'

const drawerStyle = { display: 'flex', flex: 1, backgroundColor: 'white' }

const AppHeader = () => {
    const offset = useScroll()
    const { width } = useWH()

    const [isOpen, setIsOpen] = useState(false)
    const [navHeight, setNavHeight] = useState(90)

    const showSideBar = true

    useEffect(() => {
        setNavHeight((navHeight) => getNavHeight(offset, navHeight))
    }, [offset])

    const headerItems = useHeaderItems()

    const toggleDrawer = () => {
        setIsOpen((state) => !state)
    }

    const showMenu = () => {
        toggleDrawer()
    }

    return (
        <nav style={{ height: navHeight }} className={`navbar sticky-top container-fluid flex-nowrap`}>
            <div className='bg-light px-lg-5 px-1 h-100 centered brand'>
                <Link to='/'>
                    <Logo {...logoProps(width)} />
                </Link>
            </div>
            <div className='main-navbar'>
                <HeaderMenu items={headerItems} />
                <div />
                <HeaderDropDown items={headerItems} />
            </div>

            <div className='d-flex flex-row align-items-center h-100 bg-primary pe-2'>
                <LangSelector className='me-2 bg-light p-2' />
                <UserInfo />
                {showSideBar && (
                    <>
                        <button
                            className='btn btn-link text-dark ms-md-2'
                            data-testid={`btn-hamburger`}
                            onClick={showMenu}
                        >
                            <Bars />
                        </button>
                        <Drawer
                            open={isOpen}
                            onClose={toggleDrawer}
                            direction='right'
                            zIndex={1025}
                            size={'min(320px, 80vw)'}
                            style={drawerStyle}
                        >
                            <SideMenu onAction={toggleDrawer} />
                        </Drawer>
                    </>
                )}
            </div>
        </nav>
    )
}

export default AppHeader
