import { openAPI, signedAPI, ENDPOINTS } from './api'

export const getCounties = async () => {
    const { data } = await openAPI.get(ENDPOINTS.locations.counties)
    return data
}

export const getAdministrationUnits = async () => {
    const { data } = await openAPI.get(ENDPOINTS.locations.units)
    return data
}

export const getProductCategories = async () => {
    const { data } = await signedAPI.get(ENDPOINTS.locations.ProductCategories)
    return data
}

export const getColoursOfPackaging = async () => {
    const { data } = await signedAPI.get(ENDPOINTS.locations.ColoursOfPackaging)
    return data
}

export const getPackagingMaterials = async () => {
    const { data } = await signedAPI.get(ENDPOINTS.locations.PackagingMaterials)
    return data
}

export const getFuturePackagingMaterials = async () => {
    const { data } = await signedAPI.get(ENDPOINTS.locations.FuturePackagingMaterials)
    return data
}

export const getOxygenBarriers = async () => {
    const { data } = await signedAPI.get(ENDPOINTS.locations.OxygenBarriers)
    return data
}

export const getRVMModels = async () => {
    const { data } = await signedAPI.get(ENDPOINTS.locations.RVMModels)
    return data
}

export const getBusinessCategories = async () => {
    const { data } = await signedAPI.get(ENDPOINTS.locations.BusinessCategories)
    return data
}

export const getBusinessTypes = async (value = 'All') => {
    const { data } = await signedAPI.get(ENDPOINTS.locations.BusinessTypes(value))
    return data
}
