export const logoProps = (width) => {
    return width < 768
        ? {
              width: 88,
              height: 44,
          }
        : {
              width: 103,
              height: 59,
          }
}

export const getNavHeight = (offset, current) => {
    if (offset > 90) return 60
    if (offset < 60) return 90
    return current

    // return Math.max(60, Math.min(90, 90 - (offset - 90)))
}
