import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { restoreLanguage, detectLocale } from './utils'

import languages from './resources/languages'

const userLocale = detectLocale()

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: languages,
        lng: userLocale || 'en',
        fallbackLng: 'en',

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    })

const value = restoreLanguage()
if (value !== userLocale) i18n.changeLanguage(value)

export default i18n
