import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const ConfirmationButton = ({
    title = 'delete',
    variant = 'btn-danger',
    yes_title = 'delete',
    yes_variant = 'btn-danger',
    no_title = 'Cancel',
    no_variant = 'btn-light',
    jsx_title = null,
    onConfirm = () => {},
    loading = false,
}) => {
    const { t } = useTranslation()
    const [confirm, setConfirm] = useState(false)

    useEffect(() => {
        const timer = confirm ? setTimeout(() => onCancel(), 5000) : null
        return () => clearTimeout(timer)
    }, [confirm])

    const onAttempt = () => {
        setConfirm(true)
    }

    const onAction = () => {
        setConfirm(false)
        onConfirm()
    }

    const onCancel = () => {
        setConfirm(false)
    }

    return (
        <div>
            {confirm ? (
                <div>
                    <button
                        type='button'
                        data-testid='btn-yes'
                        className={`btn ${yes_variant}`}
                        onClick={onAction}
                        disabled={loading}
                    >
                        {t(yes_title)}
                    </button>
                    <button
                        type='button'
                        data-testid='btn-cancel'
                        className={`ms-3 btn ${no_variant}`}
                        onClick={onCancel}
                    >
                        {t(no_title)}
                    </button>
                </div>
            ) : (
                <button type='button' data-testid='btn-action' className={`btn ${variant}`} onClick={onAttempt}>
                    {jsx_title || t(title)}
                </button>
            )}
        </div>
    )
}

export default ConfirmationButton
