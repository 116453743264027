const Loading = ({ centered, className = '', ...props }) => {
    const spinner = (
        <div className={`spinner-border text-success ${className}`} role='status' {...props}>
            <span className='visually-hidden'>Loading...</span>
        </div>
    )

    return centered ? <div className='d-flex justify-content-center'>{spinner}</div> : spinner
}

export default Loading
