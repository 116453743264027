import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useIsAuthenticated } from '@azure/msal-react'

import { logAnalyticsEvent } from '../resources/firebase'

import useAuth from '../hooks/useAuth'
import AuthApp from './AuthApp'
import App from './App'

const Main = () => {
    const location = useLocation()
    const isAuthenticated = useIsAuthenticated()
    const { msal } = useAuth()
    useEffect(() => {
        window.scrollTo(0, 0)
        logAnalyticsEvent('page_view', { page_location: location.pathname, page_title: location.pathname })
    }, [location.pathname])

    return isAuthenticated && !!msal ? <App /> : <AuthApp />
}

export default Main
