import { Navigate } from 'react-router-dom'

import lazy from './lazy'
import { PartnerTypes, Statuses } from '../resources/constants'
import useAuth from '../hooks/useAuth'

const Landing = lazy('Landing')
const RegisterRetailer = lazy('Register', { partnerType: PartnerTypes.Trader })
const RegisterProducer = lazy('Register', { partnerType: PartnerTypes.Producer })

const Dashboard = lazy('Dashboard')
const Applications = lazy('Applications', { title: 'Received Applications' })
// const Companies = lazy('Applications', { title: 'Your companies' })
const ProducersCompanies = lazy('Applications', { title: 'Producers', partnerType: PartnerTypes.Producer })
const TradersCompanies = lazy('Applications', { title: 'Retailers', partnerType: PartnerTypes.Trader })
const CompanyInfo = lazy('CompanyInfo')
const CompanyEditor = lazy('CompanyEditor')
const CurrentCompany = lazy('CurrentCompany')

const CPS = lazy('CPS')
const CP = lazy('CP')
const CPEditor = lazy('CPEditor')
const CPUpload = lazy('CPUpload')

const TraderReports = lazy('TraderReports')
const TraderReport = lazy('TraderReport')
const TraderDocs = lazy('Docs', { isProducer: false })

const ProducerReports = lazy('ProducerReports')
const ProducerMaterial = lazy('ProducerMaterial')
const ProducerReport = lazy('ProducerReport')
const ProducerDocs = lazy('Docs', { isProducer: true })

const Profile = lazy('Profile')
const Users = lazy('Users')
const User = lazy('User')
const UserNew = lazy('UserNew')

const Videos = lazy('Videos')
// const Consult = lazy('Consult')
const ContractForReviewRetailer = lazy('ContractForReviewRetailer')

const NotFound = lazy('NotFound')
const FormControls = lazy('FormControls')

const Products = lazy('Products')
const ProductsForPartner = lazy('ProductsForPartner')
const VouchersForPartner = lazy('VouchersForPartner')
const InvoicesForPartner = lazy('InvoicesForPartner')
const PickupForm = lazy('PickupForm')
const RetailerReports = lazy('RetailerReports')

const RegistrationInvoicesForPartner = lazy('RegistrationInvoicesForPartner')
const ProductPlacementForPartner = lazy('ProductPlacementForPartner')
const ProductPlacementInvoicesForPartner = lazy('ProductPlacementInvoicesForPartner')

const Placeholder = lazy('Placeholder')

const TraderIndex = () => {
    const { isManager } = useAuth()
    return <Navigate to={isManager ? '/retailer/partners' : '/retailer/cps'} replace />
}

const ProducerIndex = () => {
    const { isManager } = useAuth()
    return <Navigate to={isManager ? '/producer/partners' : '/producer/reports'} replace />
}

export const rootItem = {
    path: '/',
    element: <Dashboard />,
}

export const fakeItems = [
    {
        path: '/return/partners/:partnerId/products',
        title: 'Products',
        element: <Products />,
    },
    {
        path: '/return/products',
        title: 'Products',
        element: <Products />,
    },
]

export const appRoutes = [
    {
        path: '/home',
        title: 'home',
        alias: ['/'],
        children: [
            {
                path: '/home',
                title: 'Overview',
                index: true,
                element: <Dashboard />,
                comparator: (pathname) => {
                    return ['/', '/home'].includes(pathname)
                },
            },
            {
                path: '/home/dashboard',
                title: 'Dashboard',
                element: <Dashboard />,
                isHidden: true,
            },
            {
                path: '/home/videos',
                title: 'Videos',
                element: <Videos />,
            },
            // {
            //     path: '/home/contract-producer',
            //     title: 'ContractForReview',
            //     element: <Consult />,
            //     include: [PartnerTypes.Producer],
            // },
            // {
            //     path: '/home/contract-producer',
            //     title: 'ContractForReview',
            //     element: <ContractForReview />,
            //     include: [PartnerTypes.Producer],
            // },
            // {
            //     path: '/home/contract-retailer',
            //     title: 'contract_consultation',
            //     element: <ContractForReviewRetailer />,
            //     include: [PartnerTypes.Trader],
            // },
        ],
    },

    {
        path: '/manager',
        title: 'Manager',
        include: [PartnerTypes.Returo],
        children: [
            {
                index: true,
                isHidden: true,
                element: <Applications />,
            },
            {
                path: '/manager/partners',
                title: 'Partners',
                element: <Applications />,
                comparator: (pathname) => {
                    return ['/manager'].includes(pathname) || pathname.startsWith('/manager/partners')
                },
            },
            {
                path: '/manager/partners/:id',
                isHidden: true,
                element: <CompanyInfo />,
            },
            {
                path: '/return/products',
                title: 'Products',
                element: <Navigate to='/return/products' replace />,
                justLink: true,
                role: ['Admin', 'Manager'],
            },

            // {
            //     path: '/manager/docs',
            //     title: 'docs_and_contracts',
            //     element: <Placeholder />,
            // },
        ],
    },

    {
        path: '/producer',
        title: 'Producer',
        include: [PartnerTypes.Returo, PartnerTypes.Producer],
        children: [
            {
                index: true,
                isHidden: true,
                element: <ProducerIndex />,
            },
            {
                path: '/producer/partners',
                title: 'Partners',
                element: <ProducersCompanies />,
                include: [PartnerTypes.Returo],
            },
            {
                path: '/producer/partners/:id',
                isHidden: true,
                include: [PartnerTypes.Returo],
                element: <CompanyInfo />,
            },

            {
                path: '/producer/reports',
                title: 'Reports',
                element: <ProducerReports />,
                role: ['Admin', 'Manager'],
            },
            {
                path: '/producer/reports/:partner_id/:id',
                isHidden: true,
                element: <ProducerReport />,
                role: ['Admin', 'Manager'],
            },

            {
                path: '/producer/docs',
                title: 'docs_and_contracts',
                element: <ProducerDocs />,
                role: ['Admin', 'Manager'],
            },
            {
                path: '/return/partners/:partnerId/products',
                title: 'Products',
                element: <ProductsForPartner />,
                include: [PartnerTypes.Producer],
                justLink: true,
                statuses: [Statuses.Contracted],
            },
            {
                path: '/return/products',
                title: 'Products',
                element: <Navigate to='/return/products' replace />,
                include: [PartnerTypes.Returo],
                justLink: true,
                role: ['Admin', 'Manager'],
            },
            {
                path: '/producer/forecast',
                title: 'Forecast',
                element: <ProducerMaterial />,
                role: ['Admin', 'User'],
                include: [PartnerTypes.Producer],
                statuses: [Statuses.Contracted],
            },

            {
                path: '/return/invoices/producer/registration-invoices',
                title: 'registration_invoices',
                element: <Navigate to='/return/invoices/producer/registration-invoices' replace />,
                justLink: true,
                include: [PartnerTypes.Returo],
                role: ['Admin', 'Manager'],
            },
            {
                path: '/return/products/product-placement',
                title: 'product_placement',
                element: <Navigate to='/return/products/product-placement' replace />,
                justLink: true,
                include: [PartnerTypes.Returo],
                role: ['Admin', 'Manager'],
            },
            {
                path: '/return/invoices/producer/product-placement-invoices',
                title: 'product_placement_invoices',
                element: <Navigate to='/return/invoices/producer/product-placement-invoices' replace />,
                justLink: true,
                include: [PartnerTypes.Returo],
                role: ['Admin', 'Manager'],
            },

            {
                path: '/return/partners/:partnerId/producer/registration-invoices',
                title: 'registration_invoices',
                element: <RegistrationInvoicesForPartner />,
                justLink: true,
                include: [PartnerTypes.Producer],
                statuses: [Statuses.Contracted],
                role: ['Admin'],
            },
            {
                path: '/return/partners/:partnerId/producer/product-placement',
                title: 'product_placement',
                element: <ProductPlacementForPartner />,
                justLink: true,
                include: [PartnerTypes.Producer],
                statuses: [Statuses.Contracted],
                // role: ['Admin'],
            },
            {
                path: '/return/partners/:partnerId/producer/product-placement-invoices',
                title: 'product_placement_invoices',
                element: <ProductPlacementInvoicesForPartner />,
                justLink: true,
                include: [PartnerTypes.Producer],
                statuses: [Statuses.Contracted],
                role: ['Admin'],
            },
        ],
    },

    {
        path: '/retailer',
        title: 'Retailer',
        include: [PartnerTypes.Returo, PartnerTypes.Trader],
        children: [
            {
                index: true,
                isHidden: true,
                element: <TraderIndex />,
            },
            {
                path: '/retailer/partners',
                title: 'Partners',
                include: [PartnerTypes.Returo],
                element: <TradersCompanies />,
            },
            {
                path: '/retailer/partners/:id',
                isHidden: true,
                include: [PartnerTypes.Returo],
                element: <CompanyInfo />,
            },

            {
                path: '/retailer/cps',
                title: 'CollectionPoints',
                element: <CPS />,
                role: ['Admin', 'Manager'],
            },
            {
                path: '/retailer/cps/new',
                isHidden: true,
                include: [PartnerTypes.Trader],
                element: <CPEditor />,
                role: ['Admin', 'Manager'],
            },
            {
                path: '/retailer/cps/upload',
                isHidden: true,
                include: [PartnerTypes.Trader],
                element: <CPUpload />,
                role: ['Admin', 'Manager'],
            },
            {
                path: '/retailer/cps/:id',
                isHidden: true,
                element: <CP />,
                role: ['Admin', 'Manager'],
            },
            {
                path: '/retailer/cps/:id/edit',
                isHidden: true,
                element: <CPEditor />,
                role: ['Admin', 'Manager'],
            },

            {
                path: '/retailer/reports',
                title: 'Reports',
                element: <TraderReports />,
                role: ['Admin', 'Manager'],
            },
            {
                path: '/retailer/reports/:partner_id/:id',
                isHidden: true,
                element: <TraderReport />,
                role: ['Admin', 'Manager'],
            },

            {
                path: '/retailer/docs',
                title: 'docs_and_contracts',
                element: <TraderDocs />,
                role: ['Admin', 'Manager'],
            },

            {
                path: '/return/rvms/vouchers',
                title: 'Vouchers',
                element: <Navigate to='/return/rvms/vouchers' replace />,
                include: [PartnerTypes.Returo],
                justLink: true,
                role: ['Admin', 'Manager'],
            },
            {
                path: '/return/counting-reports',
                title: 'Invoices',
                element: <Navigate to='/return/counting-reports' replace />,
                include: [PartnerTypes.Returo],
                justLink: true,
                role: ['Admin', 'Manager'],
            },
            {
                path: '/return/partners/:partnerId/vouchers',
                title: 'Vouchers',
                element: <VouchersForPartner />,
                include: [PartnerTypes.Trader],
                justLink: true,
                statuses: [Statuses.Registered, Statuses.Contracted],
                role: ['Admin'],
            },
            {
                path: '/retailer/reports-templates',
                title: 'reports_templates',
                element: <RetailerReports />,
                statuses: [Statuses.Contracted],
            },
            {
                path: '/return/partners/:partnerId/counting-reports',
                title: 'Invoices',
                element: <InvoicesForPartner />,
                include: [PartnerTypes.Trader],
                justLink: true,
                statuses: [Statuses.Registered, Statuses.Contracted],
                role: ['Admin'],
            },
            {
                path: 'https://returosgr.ro/comercianti/eshop',
                title: 'e-Shop',
                element: <Navigate to='https://returosgr.ro/comercianti/eshop' replace />,
                include: [PartnerTypes.Trader],
                justLink: true,
                clickAction: 'eshop-notify',
                statuses: [Statuses.Contracted],
            },
            {
                path: '/retailer/pickup',
                title: 'pickup_form',
                include: [PartnerTypes.Trader],
                element: <PickupForm />,
            },
        ],
    },

    {
        path: '/profile',
        title: 'Profile',
        isHidden: true,
        children: [
            {
                index: true,
                path: '/profile',
                title: 'Profile',
                element: <Profile />,
                comparator: (pathname) => {
                    return ['/profile'].includes(pathname)
                },
            },
            {
                path: '/profile/company',
                title: 'Company',
                include: [PartnerTypes.Producer, PartnerTypes.Trader],
                element: <CurrentCompany />,
            },
            {
                path: '/profile/company/edit',
                title: 'Company',
                include: [PartnerTypes.Producer, PartnerTypes.Trader],
                isHidden: true,
                element: <CompanyEditor />,
            },
            // {
            //     path: '/profile/documents',
            //     title: 'Documents',
            //     include: [PartnerTypes.Producer, PartnerTypes.Trader],
            //     element: <Placeholder />,
            // },

            { path: '/profile/users', title: 'Users', element: <Users /> },

            {
                path: '/profile/users/new',
                isHidden: true,
                element: <UserNew />,
                role: ['Admin'],
            },
            {
                path: '/profile/users/:userId',
                isHidden: true,
                element: <User />,
            },
        ],
    },

    {
        path: '/placeholder',
        title: 'Placeholder',
        element: <Placeholder />,
        isHidden: true,
    },

    {
        path: '/ui',
        isHidden: true,
        element: <FormControls />,
    },
    {
        path: '/404',
        isHidden: true,
        element: <NotFound />,
    },

    {
        path: '*',
        isHidden: true,
        element: <Navigate to='/' replace />,
    },

    {
        path: '/return/products',
        title: 'Products',
        element: <Products />,
        isHidden: true,
    },
    {
        path: '/return/partners/:partnerId/products',
        title: 'Products',
        element: <Products />,
        isHidden: true,
    },
]

export const authRoutes = [
    {
        path: '/',
        element: <Landing />,
    },
    {
        path: '/register/retailer',
        element: <RegisterRetailer />,
    },
    {
        path: '/register/producer',
        element: <RegisterProducer />,
    },
    {
        path: '/ui',
        element: <FormControls />,
    },
    {
        path: '*',
        element: <Navigate to='/' replace />,
    },
]
