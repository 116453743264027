import LanguageButton from './LanguageButton'
import languages from '../../resources/languages'

const LangSelector = ({ className = '', suffix }) => {
    const keys = Object.keys(languages)

    return (
        <div className={'ss-join ss-rounded ss-gap-0.5 ' + className}>
            {keys.map((key) => (
                <LanguageButton
                    key={key}
                    language={key}
                    size={'ss-btn-sm ss-btn-square ss-join-item'}
                    suffix={suffix}
                />
            ))}
        </div>
    )
}

export default LangSelector
