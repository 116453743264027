import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const MenuItem = ({ item, isActive }) => {
    const { t } = useTranslation()

    const className = isActive ? 'header-link-active' : ''

    return (
        <Link to={item.path} data-testid={`menu-${item.path}`} className={`header-link ${className}`}>
            {t(item.title)}
        </Link>
    )
}

export default MenuItem
