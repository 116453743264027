import { useTranslation } from 'react-i18next'

import { saveLanguage } from '../../utils'

const LanguageButton = ({ language, size = '', suffix = '' }) => {
    const { i18n } = useTranslation()

    const onClick = () => {
        if (i18n.language !== language) {
            i18n.changeLanguage(language)
            saveLanguage(language)
        }
    }

    const className = i18n.language === language ? `lang ss-text-white ss-pointer-events-none` : `lang ss-text-black`

    return (
        <button
            className={`ss-btn ss-btn-primary  ${size} ${className} ${suffix}`}
            data-testid={`btn-${language}`}
            onClick={onClick}
        >
            {language.toUpperCase()}
        </button>
    )
}

export default LanguageButton
