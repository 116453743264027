import { useState, useEffect } from 'react'

const getWindowDimensions = () => {
    const { innerWidth, innerHeight } = window

    return {
        width: innerWidth,
        height: innerHeight,
    }
}

const useWH = () => {
    const [wh, setWH] = useState(getWindowDimensions())

    useEffect(() => {
        const onResize = () => setWH(getWindowDimensions())

        window.addEventListener('resize', onResize)
        return () => window.removeEventListener('resize', onResize)
    }, [])

    return wh
}

export default useWH
