import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { getPartners } from '../api/admin'
import { listState, listReducers } from './utils'

const loadApplications = createAsyncThunk(
    'apps/loadApplications',
    async (partnerType, { getState, rejectWithValue }) => {
        try {
            const { page, pageSize, search, order, filter } = getState().apps

            const params = {
                PageNumber: page,
                PageSize: pageSize,
                Order: order || 'PartnerId:asc',
            }

            for (let [key, values] of Object.entries(filter)) {
                if ((values || []).length) params[key] = values.join(',')
            }

            if (search.length > 2) params['SearchText'] = search
            // if (order) params['Order'] = order
            if (partnerType) params['PartnerType'] = partnerType

            const response = await getPartners(params)
            return response
        } catch (error) {
            console.log(error)
            return rejectWithValue([], error)
        }
    }
)

const slice = createSlice({
    name: 'apps',
    initialState: listState,
    reducers: {
        ...listReducers,
    },

    extraReducers: (builder) => {
        builder
            .addCase(loadApplications.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.error = null
                const { Items = [], TotalItemCount = 0 } = payload
                state.items = Items
                state.total = TotalItemCount
            })
            .addCase(loadApplications.pending, (state) => {
                state.isLoading = true
            })
            .addCase(loadApplications.rejected, (state, { error }) => {
                state.isLoading = false
                state.error = error
            })
    },
})

const apps = slice.reducer
const actions = {
    loadApplications,
    ...slice.actions,
}
export { apps }
export default actions
