import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const DropLine = ({ item, isActive }) => {
    const { t } = useTranslation()

    const className = isActive ? 'ss-bg-primary' : ''
    return (
        <li key={item.path}>
            <Link
                className={`nounderline ss-text-black ${className}`}
                data-testid={`dropdown-${item.path}`}
                to={item.path}
            >
                {t(item.title)}
            </Link>
        </li>
    )
}

export default DropLine
