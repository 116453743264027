const dev_clientId = '2150d9eb-dcf8-455c-8c5b-316127ef3298'
const dev_authority = 'https://login-dev.returosgr.ro/tfp/9b927b03-623d-483f-802e-01a7473b71f4/B2C_1A_SIGNIN_ONLY'
const dev_knownauth = 'login-dev.returosgr.ro'

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENTID || dev_clientId,
        authority: process.env.REACT_APP_AUTHORITY || dev_authority,
        knownAuthorities: [process.env.REACT_APP_KNOWNAUTH || dev_knownauth],
        redirectUri: process.env.REACT_APP_WEB_URL || 'http://localhost:3000/',
        postLogoutRedirectUri: process.env.REACT_APP_WEB_URL || 'http://localhost:3000/',
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
}

export const loginRequest = {
    scopes: [],
    forceRefresh: true,
}

export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
}
