import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import useAuth from '../../hooks/useAuth'

import Avatar from '../../components/Avatar'

const UserInfo = () => {
    const location = useLocation()
    const { t } = useTranslation()
    const { identity, currentPartner, permissions, isManager, isProducer, isRetailer } = useAuth()

    const isActive = location.pathname.startsWith('/profile')
    const container = isActive ? 'header-link-active' : ''
    const body = isActive ? 'header-avatar-active' : ''

    const perm = permissions.find((v) => v.entity_id === currentPartner?.Id) || permissions[0]

    return (
        <Link
            to={'/profile'}
            data-testid={'/profile'}
            className={`h-100 header-link header-avatar px-md-3  ${container}`}
        >
            {(isProducer || isRetailer) && (
                <div className='me-2 d-none d-md-block р-100'>
                    <div className={'header-avatar-title text-end ' + body}>{currentPartner?.Company?.CompanyName}</div>
                    <div className={'header-avatar-subtitle text-end ' + body}>{t(currentPartner?.PartnerType)}</div>
                </div>
            )}
            <Avatar user={identity} className='bg-light text-dark' />
            <div className='ms-md-2 d-none d-md-block р-100'>
                <div className={'header-avatar-title ' + body}>{identity?.name || 'Guest'}</div>
                <div className={'header-avatar-subtitle ' + body}>
                    {t(perm?.role) || 'no role'}
                    {isManager && <span className='ms-1'>| Returo</span>}
                </div>
            </div>
        </Link>
    )
}

export default UserInfo
