import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'def',
    initialState: {
        fluid: 'xl',
        features: {},
    },
    reducers: {
        setFluid: (state, action) => {
            state.fluid = action.payload
        },
        toggleFluid: (state, action) => {
            state.fluid = state.fluid === 'xl' ? 'fluid' : 'xl'
        },
    },
})

const def = slice.reducer
export { def }
export default slice.actions
