import React, { lazy, Suspense } from 'react'
import Loading from '../components/Loading'

const FallbackPage = () => <Loading centered />

const lazyWithSuspense = (name, props = {}) => {
    const Component = lazy(() => import(`../pages/${name}/`))
    const Suspended = () => (
        <Suspense fallback={<FallbackPage />}>
            <Component {...props} />
        </Suspense>
    )
    return Suspended
}

export default lazyWithSuspense
