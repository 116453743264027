import { useSelector } from 'react-redux'

const Container = ({ className = '', ...props }) => {
    const { fluid } = useSelector((state) => state.def)
    const classValue = `${className} container-${fluid}`

    return <div className={classValue} {...props} />
}

export default Container
