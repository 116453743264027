import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import TextInput from '../components/TextInput'
import { ButtonLight, ButtonPrimary } from '../components/Button'

import { XMark } from '../resources/icons'

import useDebounce from '../hooks/useDebounce'
import useAuth from '../hooks/useAuth'
import auth from '../reducers/auth'
import { getPartners } from '../api/admin'
import { Statuses } from '../resources/constants'

const PartnerSelect = ({ onAction = () => {} }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { currentPartner } = useAuth()
    const { t } = useTranslation()

    const [data, setData] = useState([])
    const [value, setValue] = useState('')
    const [selected, setSelected] = useState(null)

    useEffect(() => {
        if (currentPartner) setSelected(currentPartner)
    }, [currentPartner])

    const dvalue = useDebounce(value, 500)

    useEffect(() => {
        const load = async (inputValue) => {
            try {
                const params = { PageNumber: 0, PageSize: 20, Order: 'PartnerId:asc' }
                if (inputValue) params['searchText'] = inputValue
                // params['PartnerStatus'] = [Statuses.Activated, Statuses.Contracted].join(',')

                const response = await getPartners(params)
                setData(response.Items.filter((v) => ![Statuses.Deactivated, Statuses.Created].includes(v.Status)))
            } catch (error) {
                setData([])
            }
        }

        load(dvalue)
    }, [dvalue])

    const onSetDefault = () => {
        if (selected) dispatch(auth.setPartner(selected))
        navigate('/')
        onAction()
    }

    return (
        <div>
            <TextInput
                value={value}
                onChange={(e) => setValue(e.target.value)}
                containerClass='mb-0'
                title='Partner'
                name='CustomerName'
                placeholder='partner_placeholder'
                trailing={value.length > 0 ? <ButtonLight leading={<XMark />} onClick={() => setValue('')} /> : null}
            />
            <div className='list-group mb-3'>
                {data.map((data) => (
                    <div
                        key={data.Id}
                        className={`list-group-item list-group-item-action ${data.Id === selected?.Id ? 'active' : ''}`}
                        onClick={() => setSelected((selected) => (selected?.Id === data.Id ? null : data))}
                    >
                        <div className='d-flex justify-content-between '>
                            <div className='fw-bold small'>{data.Company?.CompanyName}</div>
                            <div className='small'>{t(data.PartnerStatus)}</div>
                        </div>
                        <div className='d-flex justify-content-between '>
                            <div className='small'>{data.PartnerId}</div>
                            <div className='small'>{t(data.PartnerType)}</div>
                        </div>
                    </div>
                ))}
            </div>
            <ButtonPrimary
                title='set_as_default'
                data-testid={`btn-select-partner`}
                onClick={onSetDefault}
                disabled={!(selected && data.find((v) => v.Id === selected?.Id))}
            />
        </div>
    )
}

export default PartnerSelect
