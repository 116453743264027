import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { getCollectionPoints } from '../api/cps'

import { listState, listReducers } from './utils'

const loadCPS = createAsyncThunk('cps/loadCPS', async (_, { getState, rejectWithValue }) => {
    try {
        const { page, pageSize, search, order, filter, status } = getState().cps
        const { trader, ...filterRest } = filter
        const { currentPartnerId } = getState().auth

        const params = {
            PageNumber: page,
            PageSize: pageSize,
            Order: order || 'CollectionPointId:asc',
        }

        for (let [key, values] of Object.entries(filterRest)) {
            if ((values || []).length) params[key] = values.join(',')
        }

        if (search.length > 2) params['SearchText'] = search
        if (status) params['Status'] = status
        if (trader?.Id || currentPartnerId) params['TraderId'] = trader?.Id || currentPartnerId
        if (!currentPartnerId) params['PartnerActive'] = true

        const response = await getCollectionPoints(params)
        return response
    } catch (error) {
        console.log(error)
        return rejectWithValue({}, error)
    }
})

const slice = createSlice({
    name: 'cps',
    initialState: listState,
    reducers: {
        ...listReducers,
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadCPS.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.error = null
                const { Items = [], TotalItemCount = 0 } = payload
                state.items = Items
                state.total = TotalItemCount
            })
            .addCase(loadCPS.pending, (state) => {
                state.isLoading = true
            })
            .addCase(loadCPS.rejected, (state, { error }) => {
                state.isLoading = false
                state.error = error
            })
    },
})

const cps = slice.reducer
const actions = {
    loadCPS,
    ...slice.actions,
}
export { cps }
export default actions
