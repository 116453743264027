import { useTranslation } from 'react-i18next'
import Loading from './Loading'

const dark = ['none', 'light', 'link', 'outline-dark']

const Button = ({ title, variant = 'none', className = '', leading, disabled, isLoading, children, alt, ...props }) => {
    const { t } = useTranslation()
    const loadingStyle = dark.includes(variant) ? 'text-dark' : 'text-light'
    return (
        <button
            type='button'
            className={`btn btn-${variant} ${className}`}
            disabled={disabled || isLoading}
            title={alt}
            {...props}
        >
            {leading}
            {children || (title ? t(title) : null)}
            {isLoading && <Loading className={`ms-2 spinner-border-sm ${loadingStyle}`} />}
        </button>
    )
}

export default Button

export { Button }
export const ButtonPrimary = (props) => <Button variant='returo' {...props} />
export const ButtonDark = (props) => <Button variant='dark' {...props} />
export const ButtonLight = (props) => <Button variant='light' {...props} />
export const ButtonLink = (props) => <Button variant='link' {...props} />
export const ButtonDarkOutline = (props) => <Button variant='outline-dark' {...props} />
export const ButtonDanger = (props) => <Button variant='danger' {...props} />
