import { useLocation } from 'react-router-dom'
import MenuItem from './MenuItem'

const HeaderMenu = ({ items }) => {
    const location = useLocation()
    const path = location.pathname === '/' ? '/home' : location.pathname

    return (
        <div className='flex-row d-none d-md-flex h-100'>
            {items.map((item) => (
                <MenuItem key={item.path} item={item} isActive={path.startsWith(item.path)} />
            ))}
        </div>
    )
}

export default HeaderMenu
