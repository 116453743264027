import { Link } from 'react-router-dom'

import { Logo } from '../resources/icons'
import { logoProps } from './utils'

import useWH from '../hooks/useWH'

import LangSelector from './components/LangSelector'

const AuthHeader = () => {
    const { width } = useWH()

    return (
        <nav className='ss-navbar ss-sticky ss-top-0 ss-backdrop-blur-sm auth-navbar bg-auth-50'>
            <div className='ss-flex ss-container ss-justify-between ss-mx-auto '>
                <Link className='md:ss-px-4 ss-px-1' to='/'>
                    <Logo {...logoProps(width)} />
                </Link>

                <div className='ss-flex ss-items-center'>
                    <LangSelector suffix='auth' />
                </div>
            </div>
        </nav>
    )
}

export default AuthHeader
