import { useEffect, useState } from 'react'
import useAuth from '../hooks/useAuth'
import { appRoutes } from './routes'

const useHeaderItems = () => {
    const { partnerType } = useAuth()
    const [items, setItems] = useState([])

    useEffect(() => {
        const result = []
        appRoutes.forEach((m) => {
            const { path, title, isHidden, include = [] } = m

            if (!isHidden && (include.length === 0 || include.includes(partnerType))) {
                result.push({ path, title })
            }
        })
        // console.log('menu', result)
        setItems(result)
    }, [partnerType])

    return items
}

export default useHeaderItems
