import { useEffect, useState } from 'react'
import useAuth from '../hooks/useAuth'
import { cleanup } from '../utils'
import { rootItem, fakeItems, appRoutes } from './routes'

const AccessDenied = () => (
    <div className='centered m-3'>
        <div className='alert alert-danger h4' role='alert'>
            Access Denied
        </div>
    </div>
)

const getRouteItems = (items, partnerType) => {
    const result = []
    items.forEach((m) => {
        const { path, index, element, include = [], children = [], justLink } = m

        if (!justLink) {
            const theElement = include.length === 0 || include.includes(partnerType) ? element : <AccessDenied />
            const item = cleanup({ path, index, element: theElement })
            if (children.length > 0) item['children'] = getRouteItems(children, partnerType)
            result.push(item)
        }
    })
    return result
}

const useAppRoutes = () => {
    const { partnerType } = useAuth()
    const [items, setItems] = useState([rootItem, ...fakeItems])

    useEffect(() => {
        const result = getRouteItems(appRoutes, partnerType)
        setItems([rootItem, ...result])
        // console.log('routes', [rootItem, ...result])
    }, [partnerType])

    return items
}

export default useAppRoutes
