import { ENDPOINTS, signedAPI } from './api'

export const getReturoUsers = async (params) => {
    const { data } = await signedAPI.get(ENDPOINTS.returo.users, { params })
    return data
}

export const getTraderUsers = async (trader_id, params) => {
    const { data } = await signedAPI.get(ENDPOINTS.traders.users(trader_id), { params })
    return data
}

export const getProducerUsers = async (producer_id, params) => {
    const { data } = await signedAPI.get(ENDPOINTS.producers.users(producer_id), { params })
    return data
}

export const getAllUsers = async (params) => {
    const { data } = await signedAPI.get(ENDPOINTS.users.all_users, { params })
    return data
}

export const getTraderUser = async (trader_id, user_id) => {
    const { data } = await signedAPI.get(ENDPOINTS.traders.user(trader_id, user_id))
    return data
}

export const getProducerUser = async (producer_id, user_id) => {
    const { data } = await signedAPI.get(ENDPOINTS.producers.user(producer_id, user_id))
    return data
}

export const getReturoUser = async (user_id) => {
    const { data } = await signedAPI.get(ENDPOINTS.returo.user(user_id))
    return data
}

export const addReturoUser = async (payload) => {
    const { data } = await signedAPI.post(ENDPOINTS.returo.users, payload)
    return data
}

export const addTraderUser = async (trader_id, payload) => {
    const { data } = await signedAPI.post(ENDPOINTS.traders.users(trader_id), payload)
    return data
}

export const addProducerUser = async (producer_id, payload) => {
    const { data } = await signedAPI.post(ENDPOINTS.producers.users(producer_id), payload)
    return data
}

export const deleteReturoUser = async (user_id) => {
    const { data } = await signedAPI.delete(ENDPOINTS.returo.user(user_id))
    return data
}

export const deleteTraderUser = async (trader_id, user_id) => {
    const { data } = await signedAPI.delete(ENDPOINTS.traders.user(trader_id, user_id))
    return data
}

export const deleteProducerUser = async (producer_id, user_id) => {
    const { data } = await signedAPI.delete(ENDPOINTS.producers.user(producer_id, user_id))
    return data
}

export const getUserSettings = async (partner_id) => {
    const { data } = await signedAPI.get(ENDPOINTS.users.user_settings(partner_id))
    return data
}

export const sendUserSettings = async (partner_id, payload) => {
    const { data } = await signedAPI.put(ENDPOINTS.users.user_settings(partner_id), payload)
    return data
}

export const updateReturoUser = async (payload) => {
    const { data } = await signedAPI.put(ENDPOINTS.returo.users, payload)
    return data
}

export const updateTraderUser = async (trader_id, payload) => {
    const { data } = await signedAPI.put(ENDPOINTS.traders.users(trader_id), payload)
    return data
}

export const updateProducerUser = async (producer_id, payload) => {
    const { data } = await signedAPI.put(ENDPOINTS.producers.users(producer_id), payload)
    return data
}
