import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import Container from '../components/Container'
import SubItem from './components/SubItem'
import { ArrowsLeftRight } from '../resources/icons'

import useScroll from '../hooks/useScroll'
import { getNavHeight } from './utils'
import useSubmenu from './useSubmenu'

import def from '../reducers/default'

const AppSubHeader = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const offset = useScroll()
    const [navHeight, setNavHeight] = useState(90)

    const items = useSubmenu()

    useEffect(() => {
        setNavHeight((navHeight) => getNavHeight(offset, navHeight))
    }, [offset])

    const onFluid = () => {
        dispatch(def.toggleFluid())
    }

    if (!items.length) return null

    return (
        <div className='submenu-container border-bottom' style={{ top: navHeight }}>
            <Container className='nav px-1'>
                {items.map((item) => (
                    <SubItem
                        key={`item${item.path}`}
                        item={item}
                        isActive={
                            item.comparator
                                ? item.comparator(location.pathname)
                                : location.pathname.startsWith(item.path)
                        }
                    />
                ))}
            </Container>
            <div className='centered p-2'>
                <button className='btn btn-light btn-sm ' data-testid={`btn-expand`} onClick={onFluid}>
                    <ArrowsLeftRight />
                </button>
            </div>
        </div>
    )
}

export default AppSubHeader
