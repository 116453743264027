export const a2o = (a) => {
    return a.reduce((r, v) => ({ ...r, [v]: v }), {})
}

export const o2options = (o) => {
    return Object.keys(o).map((label) => ({ label, value: o[label] }))
}

export const a2options = (a) => {
    return a.map((label) => ({ label, value: label }))
}

export const Statuses = a2o([
    'Created',
    'Activated',
    'InProgress',
    'WaitingForSignature',
    'Registered',
    'RegisteredWithExemption',
    'Open',
    'Signed',
    'Contracted',
    'Deactivated',
])

export const PartnerTypes = a2o(['Returo', 'Producer', 'Trader'])

export const CollectionTypes = a2o(['None', 'RVM', 'Manual'])

export const ExemptionTypes = a2o([
    'None',
    'OnlineSalesOnly',
    'VendingSalesOnly',
    'SharedCollectionPoint',
    'SharedCollectionPointRetailers',
    'Horeca',
])

export const SurfaceAreas = a2o([
    'NoPhysicalStore',
    'Below200',
    'Between201And400',
    'Between401And600',
    'Between601And800',
    'Between801And1000',
    'Between1001And1500',
    'Between1501And2000',
    'Above2000',
])

const CONST = {
    Statuses,
    PartnerTypes,

    CollectionTypes,
    ExemptionTypes,
    SurfaceAreas,
}

export default CONST
