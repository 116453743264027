import { useLocation } from 'react-router-dom'

import { Bars } from '../../resources/icons'
import DropLine from './DropLine'

const HeaderDropDown = ({ items }) => {
    const location = useLocation()
    const path = location.pathname === '/' ? '/home' : location.pathname

    return (
        <div className='nav-item ss-flex ss-items-center md:ss-hidden'>
            <div className='ss-dropdown'>
                <div tabIndex={0} role='button' className='ss-btn'>
                    <span className='ss-me-2 max-sm:ss-hidden'>Menu</span>
                    <Bars />
                </div>
                <ul
                    tabIndex={0}
                    className='ss-dropdown-content ss-menu ss-bg-base-100 ss-rounded-box ss-z-[1] ss-w-52 ss-p-2 ss-shadow'
                >
                    {items.map((item) => (
                        <DropLine key={item.path} item={item} isActive={path.startsWith(item.path)} />
                    ))}
                </ul>
            </div>
        </div>
    )

    // return (
    //     <div className='nav-item dropdown d-flex align-items-center d-md-none'>
    //         <Link
    //             className='nav-link btn p-2 bg-light text-primary fw-bold dropdown-toggle'
    //             href='/'
    //             type='button'
    //             id='navmenu'
    //             data-bs-toggle='dropdown'
    //             aria-expanded='false'
    //         >
    //             <span className='me-2 d-none'>Menu</span>
    //             <Bars />
    //         </Link>
    //         <ul className='dropdown-menu' aria-labelledby='navmenu'>
    //             {items.map((item) => (
    //                 <DropLine key={item.path} item={item} isActive={path.startsWith(item.path)} />
    //             ))}
    //         </ul>
    //     </div>
    // )
}

export default HeaderDropDown
