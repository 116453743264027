import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {
    getCounties,
    getAdministrationUnits,
    getProductCategories,
    getColoursOfPackaging,
    getPackagingMaterials,
    getFuturePackagingMaterials,
    getOxygenBarriers,
    getRVMModels,
    getBusinessTypes,
    getBusinessCategories,
} from '../api/locations'

const loadCounties = createAsyncThunk('locations/loadCounties', async (_, { rejectWithValue }) => {
    try {
        const response = await getCounties()
        return response.sort((a, b) => (a.Name > b.Name ? 1 : -1))
    } catch (error) {
        return rejectWithValue([], error)
    }
})

const loadAdministrationUnits = createAsyncThunk(
    'locations/loadAdministrationUnits',
    async (_, { rejectWithValue }) => {
        try {
            const response = await getAdministrationUnits()
            return response.sort((a, b) => (a.Name > b.Name ? 1 : -1))
        } catch (error) {
            return rejectWithValue([], error)
        }
    }
)

const loadProductCategories = createAsyncThunk('locations/loadProductCategories', async (_, { rejectWithValue }) => {
    try {
        const response = await getProductCategories()
        return response
    } catch (error) {
        return rejectWithValue([], error)
    }
})

const loadColoursOfPackaging = createAsyncThunk('locations/loadColoursOfPackaging', async (_, { rejectWithValue }) => {
    try {
        const response = await getColoursOfPackaging()
        return response
    } catch (error) {
        return rejectWithValue([], error)
    }
})

const loadPackagingMaterials = createAsyncThunk('locations/loadPackagingMaterials', async (_, { rejectWithValue }) => {
    try {
        const response = await getPackagingMaterials()
        return response
    } catch (error) {
        return rejectWithValue([], error)
    }
})

const loadFuturePackagingMaterials = createAsyncThunk(
    'locations/loadFuturePackagingMaterials',
    async (_, { rejectWithValue }) => {
        try {
            const response = await getFuturePackagingMaterials()
            return response
        } catch (error) {
            return rejectWithValue([], error)
        }
    }
)

const loadOxygenBarriers = createAsyncThunk('locations/loadOxygenBarriers', async (_, { rejectWithValue }) => {
    try {
        const response = await getOxygenBarriers()
        return response
    } catch (error) {
        return rejectWithValue([], error)
    }
})

const loadTruckAccess = createAsyncThunk('locations/loadTruckAccess', async (_, { rejectWithValue }) => {
    try {
        // const response = await getOxygenBarriers()
        return ['_3Tonne', '_5Tonne', '_7point5Tonne', '_12Tonne', '_24Tonne']
    } catch (error) {
        return rejectWithValue([], error)
    }
})

const loadSiteConstraints = createAsyncThunk('locations/loadSiteConstraints', async (_, { rejectWithValue }) => {
    try {
        // const response = await getOxygenBarriers()
        return [
            'EvidenceOfRoughSleepersAlcoholOrSubstanceAbuse',
            'GeneralSiteAccessIssues',
            'ManualHandlingIssues',
            'SiteSpecificRulesOrRestrictions',
            'IsolationRequiredForContainersAndBins',
            'NearAnEducationSite',
            'InadequateLightingForNightOrWinter',
            'UnsuitableGroundConditions',
            'FurtherInformationRequiredForSafeCollection',
            'OverheadHazards',
        ]
    } catch (error) {
        return rejectWithValue([], error)
    }
})

const loadSeasonality = createAsyncThunk('locations/loadSeasonality', async (_, { rejectWithValue }) => {
    try {
        // const response = await getOxygenBarriers()
        return ['None', 'SummerOnly', 'WinterOnly', 'DifferentSommerAndWinter']
    } catch (error) {
        return rejectWithValue([], error)
    }
})

const loadWasteCollectionFrequency = createAsyncThunk(
    'locations/loadWasteCollectionFrequency',
    async (_, { rejectWithValue }) => {
        try {
            // const response = await getOxygenBarriers()
            return ['Daily', 'TwicePerWeek', 'FourPerWeek', 'Weekly', 'Fortnightly', 'Monthly', 'AdHoc']
        } catch (error) {
            return rejectWithValue([], error)
        }
    }
)

const loadBusinessType = createAsyncThunk('locations/loadBusinessType', async (param, { rejectWithValue }) => {
    try {
        const response = await getBusinessTypes(param)
        return response
    } catch (error) {
        return rejectWithValue([], error)
    }
})

const loadBusinessCategories = createAsyncThunk('locations/loadBusinessCategories', async (_, { rejectWithValue }) => {
    try {
        const response = await getBusinessCategories()
        return response
    } catch (error) {
        return rejectWithValue([], error)
    }
})

const loadGlassStorageType = createAsyncThunk('locations/loadGlassStorageType', async (_, { rejectWithValue }) => {
    try {
        // const response = await getOxygenBarriers()
        return ['Bags', 'Bins']
    } catch (error) {
        return rejectWithValue([], error)
    }
})

const loadRVMModels = createAsyncThunk('locations/loadRVMModels', async (_, { rejectWithValue }) => {
    try {
        const response = await getRVMModels()
        return response
    } catch (error) {
        return rejectWithValue([], error)
    }
})

const initialState = {
    isLoading: false,
    counties: [],
    units: [],
    ProductCategories: [],
    ColoursOfPackaging: [],
    PackagingMaterials: [],
    FuturePackagingMaterials: [],
    OxygenBarriers: [],
    TruckAccessTonnage: [],
    SiteConstraints: [],
    Seasonality: [],
    WasteCollectionFrequency: [],
    BusinessType: [],
    BusinessCategories: [],
    GlassStorageType: [],
    RVMModels: [],
}

const slice = createSlice({
    name: 'locations',
    initialState,
    reducers: {
        reset: () => {
            return initialState
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadCounties.fulfilled, (state, { payload }) => {
                state.counties = payload
            })
            .addCase(loadAdministrationUnits.fulfilled, (state, { payload }) => {
                state.units = payload
            })
            .addCase(loadProductCategories.fulfilled, (state, { payload }) => {
                state.ProductCategories = payload
            })
            .addCase(loadColoursOfPackaging.fulfilled, (state, { payload }) => {
                state.ColoursOfPackaging = payload
            })
            .addCase(loadPackagingMaterials.fulfilled, (state, { payload }) => {
                state.PackagingMaterials = payload
            })
            .addCase(loadFuturePackagingMaterials.fulfilled, (state, { payload }) => {
                state.FuturePackagingMaterials = payload
            })
            .addCase(loadOxygenBarriers.fulfilled, (state, { payload }) => {
                state.OxygenBarriers = payload
            })
            .addCase(loadTruckAccess.fulfilled, (state, { payload }) => {
                state.TruckAccessTonnage = payload
            })
            .addCase(loadSiteConstraints.fulfilled, (state, { payload }) => {
                state.SiteConstraints = payload
            })
            .addCase(loadSeasonality.fulfilled, (state, { payload }) => {
                state.Seasonality = payload
            })
            .addCase(loadWasteCollectionFrequency.fulfilled, (state, { payload }) => {
                state.WasteCollectionFrequency = payload
            })
            .addCase(loadBusinessType.fulfilled, (state, { payload }) => {
                state.BusinessType = payload
            })
            .addCase(loadBusinessCategories.fulfilled, (state, { payload }) => {
                state.BusinessCategories = payload
            })
            .addCase(loadGlassStorageType.fulfilled, (state, { payload }) => {
                state.GlassStorageType = payload
            })
            .addCase(loadRVMModels.fulfilled, (state, { payload }) => {
                state.RVMModels = payload
            })
    },
})

const locations = slice.reducer
const actions = {
    loadCounties,
    loadAdministrationUnits,
    loadProductCategories,
    loadColoursOfPackaging,
    loadPackagingMaterials,
    loadFuturePackagingMaterials,
    loadOxygenBarriers,
    loadTruckAccess,
    loadSiteConstraints,
    loadSeasonality,
    loadWasteCollectionFrequency,
    loadBusinessType,
    loadBusinessCategories,
    loadGlassStorageType,
    loadRVMModels,
    ...slice.actions,
}
export { locations }
export default actions
