import { useMsal } from '@azure/msal-react'

import PartnerSelect from '../hoc/PartnerSelectMenu'
import { Statuses } from '../resources/constants'

import useAuth from '../hooks/useAuth'
import GroupView from '../components/GroupView'
import Avatar from '../components/Avatar'
import ConfirmationButton from '../components/ConfirmationButton'
import Button from '../components/Button'
import { XMark } from '../resources/icons'
import { useTranslation } from 'react-i18next'

const SideMenu = ({ onAction }) => {
    const { isManager } = useAuth()

    return (
        <div className='d-flex flex-grow-1 flex-column p-2' style={{ overflowY: 'auto' }}>
            <div className='text-end'>
                <Button onClick={onAction} data-testid={`btn-close`}>
                    <XMark />
                </Button>
            </div>
            <UserInfo />
            {!isManager && <PartnerSelect status={Statuses.Activated} onAction={onAction} />}
        </div>
    )
}

export default SideMenu

const UserInfo = () => {
    const { t } = useTranslation()
    const { identity, currentPartner, permissions, isProducer, isRetailer } = useAuth()

    const perm = permissions.find((v) => v.entity_id === currentPartner?.Id) || permissions[0]

    return (
        <div className='small'>
            <GroupView className='mb-3' innerClassName='px-3 py-3 bg-light'>
                <div className='d-flex flex-row mb-2'>
                    <div className='d-flex flex-grow-1 flex-column'>
                        <div className='fw-bold'>{identity?.name || 'Guest'}</div>
                        <div>{perm?.role || 'no role'}</div>
                    </div>
                    <Avatar user={identity} className='bg-dark text-light' />
                </div>
                <hr className='mt-1' />
                <LogoutSection />
            </GroupView>

            {(isProducer || isRetailer) && (
                <GroupView title={'current_partner'} className='mb-3' innerClassName='px-3 py-3 bg-light'>
                    <div className='fw-bold '>{currentPartner?.Company?.CompanyName}</div>
                    <div>{t(currentPartner?.PartnerType)}</div>
                </GroupView>
            )}
        </div>
    )
}

const LogoutSection = () => {
    const { instance } = useMsal()

    const onLogout = async () => {
        instance.logoutRedirect().catch((e) => {
            console.error(e)
        })
    }

    return (
        <div>
            <ConfirmationButton
                variant='btn-danger'
                yes_variant='btn-danger'
                no_variant='btn-dark'
                title='logout'
                yes_title='Confirm'
                onConfirm={onLogout}
            />
        </div>
    )
}
