import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useAuth from '../../hooks/useAuth'
import { sendUserSettings } from '../../api/users'

const SubItem = ({ item, isActive }) => {
    const { t } = useTranslation()
    const { currentPartnerId } = useAuth()

    const onClick = async (action) => {
        // console.log(action)
        try {
            if (currentPartnerId) {
                const res = await sendUserSettings(currentPartnerId, { EShopVisited: true })
                console.log(res)
            }
        } catch {}
    }

    return item.justLink ? (
        <a
            // href='#'
            href={item.path.replace(':partnerId', currentPartnerId)}
            data-testid={`submenu-${item.path}`}
            className={`submenu ${isActive ? 'active' : ''}`}
            onClick={item.clickAction ? () => onClick(item.clickAction) : null}
        >
            {t(item.title)}
        </a>
    ) : (
        <Link to={item.path} data-testid={`submenu-${item.path}`} className={`submenu ${isActive ? 'active' : ''}`}>
            {t(item.title)}
        </Link>
    )
}

export default SubItem
