import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'

import { setAnalyticsUser } from '../resources/firebase'
import { getIdentity } from '../api/registration'

import { PartnerTypes } from '../resources/constants'
import { getPartner } from '../api/admin'

const last_entity_id_key = 'last_entity_id'

const loadIdentity = createAsyncThunk('auth/loadIdentity', async (_, { rejectWithValue }) => {
    try {
        const response = await getIdentity()
        return response
    } catch (error) {
        return rejectWithValue({}, error)
    }
})

const loadPartner = createAsyncThunk('auth/loadPartner', async (id, { rejectWithValue }) => {
    try {
        const response = await getPartner(id)
        console.log(response)
        return response || {}
    } catch (error) {
        return rejectWithValue(null, error)
    }
})

const initialState = {
    msal: null,
    msalError: null,
    identity: null,
    currentPartnerId: null,
    currentPartner: null,
    partnerType: null,
    permissions: [],

    PartnerStatus: null,
}

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: () => {
            return initialState
        },
        setMSALResponse: (state, action) => {
            const msal = action.payload
            global.msal = msal
            state.msal = msal
            state.msalError = null
            setAnalyticsUser(msal?.account)
        },
        setMSALError: (state, action) => {
            state.msalError = action.payload
        },
        setPartner: (state, { payload }) => {
            // console.log(payload)
            state.currentPartner = payload
            state.currentPartnerId = payload?.Id
            state.partnerType = payload?.PartnerType
            state.isProducer = payload?.PartnerType === PartnerTypes.Producer
            state.isRetailer = payload?.PartnerType === PartnerTypes.Trader
            if (payload?.Id) localStorage.setItem(last_entity_id_key, payload?.Id)
            else localStorage.removeItem(last_entity_id_key)

            const entity = current(state.permissions).find((v) => v.entity_id === payload?.Id)
            state.Role = entity?.role
            state.PartnerStatus = payload?.PartnerStatus
        },
        clearStatus: (state) => {
            state.PartnerStatus = null
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(loadIdentity.fulfilled, (state, { payload }) => {
                const sub = payload['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier']
                state.identity = { ...payload, sub }
                const { is_returo_user, permissions = [] } = payload
                state.isManager = payload.is_returo_user
                state.permissions = permissions
                state.exp = payload.exp

                if (is_returo_user) {
                    state.currentPartnerId = null
                    state.partnerType = PartnerTypes.Returo
                    state.isProducer = false
                    state.isRetailer = false
                    const entity = permissions[0]
                    state.Role = entity?.role
                } else {
                    const last_entity_id = localStorage.getItem(last_entity_id_key)
                    const entity = permissions.find((v) => v.entity_id === last_entity_id) || permissions[0]
                    state.currentPartnerId = entity?.entity_id
                    state.partnerType = entity?.entity_type
                    state.isProducer = entity?.entity_type === PartnerTypes.Producer
                    state.isRetailer = entity?.entity_type === PartnerTypes.Trader
                    state.Role = entity?.role
                }
            })
            .addCase(loadIdentity.rejected, (state, { error }) => {})

            .addCase(loadPartner.fulfilled, (state, { payload }) => {
                state.currentPartner = payload
                state.partnerType = payload.PartnerType
                state.isProducer = payload.PartnerType === PartnerTypes.Producer
                state.isRetailer = payload.PartnerType === PartnerTypes.Trader
                state.currentPartnerId = payload.Id
                if (payload.Id) localStorage.setItem(last_entity_id_key, payload.Id)
                else localStorage.removeItem(last_entity_id_key)

                state.PartnerStatus = payload?.PartnerStatus
            })
            .addCase(loadPartner.rejected, (state, { error }) => {})
    },
})

const auth = slice.reducer
const actions = {
    loadIdentity,
    loadPartner,
    ...slice.actions,
}

export { auth }
export default actions
