const ENDPOINTS = {
    commit: process.env.REACT_APP_GIT_SHA,
    env: process.env.REACT_APP_ENV || 'dev',
    webURL: process.env.REACT_APP_WEB_URL,
    baseURL: process.env.REACT_APP_BASE_URL,
    //baseURL:        'https://api-dev.returosgr.ro/',
    //baseURL:        'https://api-test.returosgr.ro/',
    //baseURL:        'https://api.returosgr.ro/',

    refresh: 'Refresh',
    identity: {
        identity: 'Identity',
        challenge: (partner_id, user_id) => `Identity/${partner_id}/Otp/${user_id}/Challenge`,
        verify: (partner_id, user_id) => `Identity/${partner_id}/Otp/${user_id}/Verify`,
    },

    example: 'Example',
    example_id: (id) => `Example/${id}`,

    admin: {
        partners: 'Admin/Partners',
        collection_points: 'Admin/CollectionPoints',
        trader_reports: 'Admin/TraderReports',
        producer_reports: 'Admin/ProducerReports',
    },

    returo: {
        users: 'Returo/Users',
        user: (user_id) => `Returo/Users/${user_id}`,
    },

    producers: {
        registration: 'Producers/Registration',
        registration_id: (producer_id) => `Producers/${producer_id}/Registration`,
        registration_certificate: (producer_id) => `Producers/${producer_id}/Registration/Certificate`,
        registration_id_deactivate: (producer_id) => `Producers/${producer_id}/Registration/Deactivate`,

        contract: (producer_id) => `Producers/${producer_id}/Registration/Contract/Document`,
        contract_signed: (producer_id) => `Producers/${producer_id}/Registration/Contract/SignedDocument`,
        contract_signature: (producer_id) => `Producers/${producer_id}/Registration/Contract/SimpleSignature`,
        contract_finalize: (producer_id) => `Producers/${producer_id}/Registration/Contract/SimpleSignature/Submit`,

        reports: (producer_id) => `Producers/${producer_id}/Reports`,
        reports_id: (producer_id, report_id) => `Producers/${producer_id}/Reports/${report_id}`,
        reports_id_submit: (producer_id, report_id) => `Producers/${producer_id}/Reports/${report_id}/Submit`,
        reports_id_reopen: (producer_id, report_id) => `Producers/${producer_id}/Reports/${report_id}/Reopen`,
        reports_id_summary: (producer_id, report_id) => `Producers/${producer_id}/Reports/${report_id}/SummaryDocument`,
        reports_id_signed: (producer_id, report_id) =>
            `Producers/${producer_id}/Reports/${report_id}/SignedSummaryDocument`,
        reports_id_signature: (producer_id, report_id) =>
            `Producers/${producer_id}/Reports/${report_id}/SimpleSignature`,
        reports_id_signature_submit: (producer_id, report_id) =>
            `Producers/${producer_id}/Reports/${report_id}/SimpleSignature/Submit`,
        reports_id_csv: (producer_id, report_id) => `Producers/${producer_id}/Reports/${report_id}/Entries/Csv`,
        reports_id_entries: (producer_id, report_id) => `Producers/${producer_id}/Reports/${report_id}/Entries`,
        reports_id_bulk: (producer_id, report_id) => `Producers/${producer_id}/Reports/${report_id}/Entries/BulkUpload`,

        users: (producer_id) => `Producers/${producer_id}/Users`,
        user: (producer_id, user_id) => `Producers/${producer_id}/Users/${user_id}`,

        reports_material_id: (producer_id) => `Producers/${producer_id}/FutureMaterialReport/Entries`,
    },

    traders: {
        registration: 'Traders/Registration',
        registration_id: (trader_id) => `Traders/${trader_id}/Registration`,
        registration_certificate: (trader_id) => `Traders/${trader_id}/Registration/Certificate`,
        registration_id_deactivate: (trader_id) => `Traders/${trader_id}/Registration/Deactivate`,

        contract: (trader_id) => `Traders/${trader_id}/Registration/Contract/Document`,
        contract_signed: (trader_id) => `Traders/${trader_id}/Registration/Contract/SignedDocument`,
        contract_signature: (trader_id) => `Traders/${trader_id}/Registration/Contract/SimpleSignature`,
        contract_finalize: (trader_id) => `Traders/${trader_id}/Registration/Contract/SimpleSignature/Submit`,

        collection_points: (trader_id) => `Traders/${trader_id}/CollectionPoints`,
        collection_points_id: (cp_id) => `Traders/CollectionPoints/${cp_id}`,
        collection_points_export: (cp_id) => `Traders/CollectionPoints/${cp_id}/ChangeLog`,
        collection_points_upload: (id) => `Traders/${id}/CollectionPoints/Upload`,
        collection_points_csv: (trader_id) => `Traders/${trader_id}/CollectionPoints/Download`,
        collection_points_register: (trader_id, cp_id) => `Traders/${trader_id}/CollectionPoints/${cp_id}/Register`,

        collection_points_rvm: (trader_id, cp_id) => `Traders/${trader_id}/CollectionPoints/${cp_id}/RvmMachines`,
        collection_points_rvm_id: (trader_id, cp_id, rvm_id) =>
            `Traders/${trader_id}/CollectionPoints/${cp_id}/RvmMachines/${rvm_id}`,

        reports: (trader_id) => `Traders/${trader_id}/Reports`,
        reports_id: (trader_id, report_id) => `Traders/${trader_id}/Reports/${report_id}`,
        reports_id_submit: (trader_id, report_id) => `Traders/${trader_id}/Reports/${report_id}/Submit`,
        reports_id_reopen: (trader_id, report_id) => `Traders/${trader_id}/Reports/${report_id}/Reopen`,
        reports_id_summary: (trader_id, report_id) => `Traders/${trader_id}/Reports/${report_id}/SummaryDocument`,
        reports_id_signed: (trader_id, report_id) => `Traders/${trader_id}/Reports/${report_id}/SignedSummaryDocument`,
        reports_id_signature: (trader_id, report_id) => `Traders/${trader_id}/Reports/${report_id}/SimpleSignature`,
        reports_id_signature_submit: (trader_id, report_id) =>
            `Traders/${trader_id}/Reports/${report_id}/SimpleSignature/Submit`,
        reports_id_csv: (trader_id, report_id) => `Traders/${trader_id}/Reports/${report_id}/Entries/Csv`,
        reports_id_entries: (trader_id, report_id) => `Traders/${trader_id}/Reports/${report_id}/Entries`,
        reports_id_bulk: (trader_id, report_id) => `Traders/${trader_id}/Reports/${report_id}/Entries/BulkUpload`,

        users: (trader_id) => `Traders/${trader_id}/Users`,
        user: (trader_id, user_id) => `Traders/${trader_id}/Users/${user_id}`,

        retailer_declaration: (trader_id) => `Traders/${trader_id}/Registration/RetailerDeclaration`,
    },

    companies: {
        search: (id) => `Companies/Search/${id}`,
    },

    users: {
        invite: 'Invite',

        all_users: `Admin/Users`,
        user_settings: (partner_id) => `Returo/Users/settings/${partner_id}`,
    },

    locations: {
        counties: 'Locations/Counties',
        units: 'Locations/AdministrationUnits',
        geocodeByAddress: 'api/GoogleMaps/GeocodeByAdress',
        geocodeByLatLng: 'GoogleMaps/GeocodeByLatLng',

        ProductCategories: 'Producers/ProductCategories',
        ColoursOfPackaging: 'Producers/ColoursOfPackaging',
        PackagingMaterials: 'Producers/PackagingMaterials',
        FuturePackagingMaterials: 'Producers/FutureMaterialReportCategories',
        OxygenBarriers: 'Producers/OxygenBarriers',
        RVMModels: 'RVMModels',
        BusinessCategories: 'Producers/BusinessCategories',
        BusinessTypes: (value) => `Producers/BusinessTypes/${value}`,
    },
}

export default ENDPOINTS
