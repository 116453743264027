import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import auth from '../reducers/auth'
import apps from '../reducers/apps'
import users from '../reducers/users'
import cps from '../reducers/cps'
import locations from '../reducers/locations'
import reptrader from '../reducers/reptrader'
import reproducer from '../reducers/reproducer'
import docs from '../reducers/docs'

import useAuth from '../hooks/useAuth'

const ResetContent = ({ userinfo = false }) => {
    const dispatch = useDispatch()
    const { currentPartnerId } = useAuth()

    useEffect(() => {
        if (userinfo) dispatch(auth.reset())

        dispatch(apps.reset())
        dispatch(users.reset())
        dispatch(cps.reset())
        dispatch(locations.reset())
        dispatch(reptrader.reset())
        dispatch(reproducer.reset())
        dispatch(docs.reset())
    }, [dispatch, currentPartnerId, userinfo])

    return null
}

export default ResetContent
