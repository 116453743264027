import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { getDocs } from '../api/docs'
import { Statuses } from '../resources/constants'

import { listState, listReducers } from './utils'

const loadDocs = createAsyncThunk('docs/loadDocs', async (isProducer, { getState, rejectWithValue }) => {
    try {
        const { page, pageSize, filter, order } = getState().docs
        // const { isProducer } = getState().auth
        const { partner, ...filterRest } = filter
        const { currentPartnerId } = getState().auth

        const params = {
            PageNumber: page,
            PageSize: pageSize,
        }

        for (let [key, values] of Object.entries(filterRest)) {
            if ((values || []).length) params[key] = values.join(',')
        }

        // if (search.length > 2) params['SearchText'] = search
        params['Status'] = Statuses.Signed
        if (order) params['Order'] = order
        if (!currentPartnerId) params['PartnerActive'] = true

        const response = await getDocs(isProducer, partner?.Id || currentPartnerId, params)
        // console.log(response)
        return response
    } catch (error) {
        console.log(error)
        return rejectWithValue({}, error)
    }
})

const slice = createSlice({
    name: 'docs',
    initialState: listState,
    reducers: {
        ...listReducers,
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadDocs.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.error = null
                const { Items = [], TotalItemCount = 0 } = payload
                state.items = Items
                state.total = TotalItemCount
            })
            .addCase(loadDocs.pending, (state) => {
                state.isLoading = true
            })
            .addCase(loadDocs.rejected, (state, { error }) => {
                state.isLoading = false
                state.error = error
            })
    },
})

const docs = slice.reducer
const actions = {
    loadDocs,
    ...slice.actions,
}
export { docs }
export default actions
