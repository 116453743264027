import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Modal } from 'bootstrap'
import auth from '../reducers/auth'
import useAuth from '../hooks/useAuth'
import { Statuses } from '../resources/constants'
import { getUserSettings } from '../api/users'
import Enrich from '../components/Enrich'

export const PartnerStatusPopup = () => {
    const { currentPartnerId, isManager, isProducer, isRetailer, PartnerStatus, identity } = useAuth()

    const dispatch = useDispatch()
    const ref = useRef()
    const cref = useRef()
    const { t } = useTranslation()

    const [focused, setFocused] = useState(false)

    const key = ['popup', isProducer ? 'p' : isRetailer ? 'r' : '', PartnerStatus].join('_')
    const localized = t(key)
    const message = localized === key ? null : localized
    const hasMessage = localized !== key

    useEffect(() => {
        if (hasMessage) {
            const modal = new Modal(ref.current)
            modal?.show()
        }
    }, [currentPartnerId, hasMessage, key])

    useEffect(() => {
        if (focused) {
            let timer60 = setInterval(() => {
                dispatch(auth.loadPartner(currentPartnerId))
            }, 1000 * 60)

            let timer3600 = setInterval(() => {
                dispatch(auth.clearStatus())
            }, 1000 * 60 * 60)

            return () => {
                clearInterval(timer60)
                clearInterval(timer3600)
            }
        } else {
            //nothing
        }
    }, [dispatch, currentPartnerId, focused])

    useEffect(() => {
        const onFocus = () => {
            setFocused(true)
        }
        const onBlur = () => {
            setFocused(false)
        }
        window.addEventListener('focus', onFocus)
        window.addEventListener('blur', onBlur)

        return () => {
            console.log('reset')
            window.removeEventListener('focus', onFocus)
            window.removeEventListener('blur', onBlur)
        }
    }, [])

    const isRetailerContracted = isRetailer && PartnerStatus === Statuses.Contracted
    const userId = identity?.sub

    useEffect(() => {
        const check = async () => {
            try {
                const res = await getUserSettings(currentPartnerId)
                if (res.EShopVisited !== true) {
                    const modal = new Modal(cref.current)
                    modal?.show()
                }
            } catch (e) {}
        }

        if (isRetailerContracted && currentPartnerId && userId) check()
    }, [isRetailerContracted, currentPartnerId, userId])

    return isManager ? null : isRetailerContracted ? (
        <div ref={cref} className={`modal fade `} id='popup' tabIndex='-1' aria-labelledby='popup' aria-hidden='true'>
            <ModalBody message={t('retailer_contracted_eshop')} />
        </div>
    ) : (
        <div ref={ref} className={`modal fade `} id='popup' tabIndex='-1' aria-labelledby='popup' aria-hidden='true'>
            <ModalBody message={message} />
        </div>
    )
}

const ModalBody = ({ message }) => {
    const { t } = useTranslation()

    return (
        <div className='modal-dialog'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <h1 className='modal-title fs-5' id='popup'>
                        {t('warning')}
                    </h1>
                    <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                </div>
                <div className='modal-body'>
                    <Enrich>{message}</Enrich>
                </div>
                <div className='modal-footer'>
                    <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                        {t('Close')}
                    </button>
                </div>
            </div>
        </div>
    )
}
