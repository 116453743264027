import { useTranslation } from 'react-i18next'

const GroupView = ({
    className = 'my-3',
    innerClassName = 'px-3 py-3',
    style,
    title,
    required,
    validation,
    children,
}) => {
    const { t } = useTranslation()

    let validationClass = ''
    switch (validation) {
        case true:
            validationClass = 'border border-success'
            break
        case false:
            validationClass = 'border border-danger'
            break
        default:
            validationClass = 'border'
            break
    }

    return (
        <div className={className}>
            <div className='form-label'>
                {t(title)}
                {required && <span className='text-danger ms-1'>*</span>}
            </div>
            <div className={`rounded ${innerClassName} ${validationClass}`} style={style}>
                {children}
            </div>
        </div>
    )
}

export default GroupView
