import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { getTraderReports } from '../api/admin'

import { listState, listReducers } from './utils'

const loadReports = createAsyncThunk('reptrader/loadReports', async (_, { getState, rejectWithValue }) => {
    try {
        const { page, pageSize, order, filter } = getState().reptrader
        const { partner, Status = [], ...filterRest } = filter
        const { currentPartnerId } = getState().auth

        const params = {
            PageNumber: page,
            PageSize: pageSize,
            Order: order || 'ReportId:asc',
        }

        for (let [key, values] of Object.entries(filterRest)) {
            if ((values || []).length) params[key] = values.join(',')
        }

        // if (search.length > 2) params['SearchText'] = search
        if (Status.length) params['Status'] = Status.join(',')
        if (partner?.Id || currentPartnerId) params['TraderId'] = partner?.Id || currentPartnerId
        if (!currentPartnerId) params['PartnerActive'] = true

        const response = await getTraderReports(params)
        return response
    } catch (error) {
        console.log(error)
        return rejectWithValue({}, error)
    }
})

const slice = createSlice({
    name: 'reptrader',
    initialState: listState,
    reducers: {
        ...listReducers,
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadReports.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.error = null
                const { Items = [], TotalItemCount = 0 } = payload
                state.items = Items
                state.total = TotalItemCount
            })
            .addCase(loadReports.pending, (state) => {
                state.isLoading = true
            })
            .addCase(loadReports.rejected, (state, { error }) => {
                state.isLoading = false
                state.error = error
            })
    },
})

const reptrader = slice.reducer
const actions = {
    loadReports,
    ...slice.actions,
}
export { reptrader }
export default actions
