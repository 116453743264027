import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useRoutes } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'

import AppHeader from './AppHeader'
import FooterCommon from './FooterCommon'
import AppSubHeader from './AppSubHeader'
import useAppRoutes from './useAppRoutes'
import ResetContent from './ResetContent'

import auth from '../reducers/auth'
import useAuth from '../hooks/useAuth'
// import { loginRequest } from '../resources/auth-config'
import { PartnerStatusPopup } from './PartnerStatusPopup'

const App = () => {
    const dispatch = useDispatch()
    const { msal, msalError, currentPartnerId, currentPartner, isManager, isRetailer, isProducer, exp } = useAuth()
    const idToken = msal?.idToken
    const { instance, accounts } = useMsal()

    useEffect(() => {
        const onFocus = async () => {
            const expired = +exp - new Date().getTime() / 1000 < 120
            if (!expired) return

            const request = {
                scopes: [],
                forceRefresh: true,
                account: accounts[0],
            }

            const response = await instance.acquireTokenSilent(request)
            dispatch(auth.setMSALResponse(response))
        }
        window.addEventListener('focus', onFocus)

        return () => window.removeEventListener('focus', onFocus)
    }, [dispatch, instance, accounts, exp])

    useEffect(() => {
        if (idToken) dispatch(auth.loadIdentity())
    }, [dispatch, idToken])

    const reloadNeeed = !isManager && (!currentPartner || currentPartner?.Id !== currentPartnerId)

    useEffect(() => {
        if (currentPartnerId && reloadNeeed) dispatch(auth.loadPartner(currentPartnerId))
    }, [dispatch, currentPartnerId, reloadNeeed])

    const appRoutes = useAppRoutes()
    const routes = useRoutes(appRoutes)

    if (msalError)
        return (
            <div className='bg-white centered p-5'>
                <div className='alert alert-danger text-center' role='alert'>
                    <pre>{JSON.stringify(msalError, 4)}</pre>
                </div>
            </div>
        )

    return (
        <div className='bg-white'>
            <AppHeader />
            <AppSubHeader />
            <div id='app' className='App'>
                {routes}
            </div>
            <FooterCommon containerClass='bg-primary' callCenterClass='text-light' />
            <ResetContent />
            {process.env.NODE_ENV === 'production' && (isRetailer || isProducer) && <PartnerStatusPopup />}
        </div>
    )
}

export default App
